import React from 'react';

import AnimationBox from '../../UI/AnimationBox'
import webIcon from '../../../assets/images/web.svg';
import iPhoneIcon from '../../../assets/images/iPhone_iPad.svg';
import iMacIcon from '../../../assets/images/iMac.svg';

const UseOnAllDevices = ({ t }) => {
  return (
    <div className="landing-use-on-all-devices">
      <div className="landing-container">
        <AnimationBox>
          <h2 className="landing-h2">{t('landing.use_on_all_devices_title')}</h2>
        </AnimationBox>
        <div className="landing-use-on-all-devices__boxes">
          <AnimationBox
            className="landing-use-on-all-devices__boxes_box"
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <div className="icon-box"><img src={webIcon} alt=""/></div>
            <p>{t('landing.use_on_all_devices_label_1')}</p>
          </AnimationBox>
          <AnimationBox
            className="landing-use-on-all-devices__boxes_box"
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <div className="icon-box"><img src={iPhoneIcon} alt=""/></div>
            <p>{t('landing.use_on_all_devices_label_2')}</p>
          </AnimationBox>
          <AnimationBox
            className="landing-use-on-all-devices__boxes_box"
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.75 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <div className="icon-box"><img src={iMacIcon} alt=""/></div>
            <p>{t('landing.use_on_all_devices_label_3')}</p>
          </AnimationBox>
        </div>
      </div>
    </div>
  );
}

export default UseOnAllDevices;