import { LinkedIn, Twitter } from '../../../assets/icons'
import logo from '../../../assets/images/carbon_logo.svg'

const Footer = ({ t }) => {
  return (
    <div className="landing-footer-wrapper">
      <div className="landing-container">
        <div className="landing-footer">
          <div className="landing-logo landing-logo--light">
            {/* <img src={logo} alt="Carbon"/> */}
            <p>Carbon</p>
          </div>
          <ul className="landing-footer__links">
            <li><a href={"assets/CGU_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('landing.footer_link_1')}</a></li>
            <li><a href={"assets/Politique_Vie_Privée_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('landing.footer_link_2')}</a></li>
            <li><a href={"assets/Politique_en_matière_de_cookies.pdf"} target="_blank" rel="noreferrer">{t('landing.footer_link_3')}</a></li>
          </ul>
          <div className="landing-footer__social">
            <a href="https://www.linkedin.com/company/getcarbonai/" target="_blank" rel="noreferrer"><LinkedIn /></a>
            <a href="https://twitter.com/get_carbon_ai" className="twitter" target="_blank" rel="noreferrer"><Twitter /></a>
          </div>
          <p className="by"><a href="https://lawstud.io/">by Lawstud.io</a></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;