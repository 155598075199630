import React, { Fragment } from 'react';
import CodeIcon from '@material-ui/icons/Code';
import LinkIcon from '@material-ui/icons/Link';

import AnimationBox from '../../UI/AnimationBox'
import Button from '../../UI/Button';
import apiPreview from '../../../assets/images/api_preview.png';
import immofacileLogo from '../../../assets/images/immofacile_logo.png';
import sharepointLogo from '../../../assets/images/sharepoint_logo.png';

const ApiSection = ({ t }) => {
  return (
    <div className="landing-api-section">
      <div className="landing-container">
        <div className="landing-api-section__text">
          <AnimationBox>
            <div className="icon">
              <CodeIcon />
            </div>
          </AnimationBox>
          <AnimationBox>
            <h2 className="landing-h2">{t('landing.api_title')}</h2>
          </AnimationBox>
          <AnimationBox>
            <p>{t('landing.api_text')}</p>
          </AnimationBox>
          <AnimationBox>
            <a 
              href="https://docs.lawstud.io/"
              className="button button--tertiary button--round"
              target="_blank"
              rel="noreferrer"
            >{t('landing.api_btn')} <LinkIcon /></a>
          </AnimationBox>
        </div>
        <div className="landing-api-section__examples">
          <AnimationBox>
            <h3>{t('landing.api_examples_heading')}</h3>
          </AnimationBox>
          <div className="landing-api-section__examples_inner">
            <div className="landing-api-section__examples_inner-box">
              <AnimationBox>
                <img src={immofacileLogo} alt="Immofacile"/>
              </AnimationBox>
              <AnimationBox>
                <h4>{t('landing.api_examples_box1_title')}</h4>
              </AnimationBox>
              <AnimationBox>
                <p>{t('landing.api_examples_box1_text')}</p>
              </AnimationBox>
            </div>
            <div className="landing-api-section__examples_inner-box">
              <AnimationBox>
                <img src={sharepointLogo} alt="Sharepoint"/>
              </AnimationBox>
              <AnimationBox>
                <h4>{t('landing.api_examples_box2_title')}</h4>
              </AnimationBox>
              <AnimationBox>
                <p>{t('landing.api_examples_box2_text')}</p>
              </AnimationBox>
            </div>
          </div>
        </div>
      </div>

      <AnimationBox className='preview'>
        <img src={apiPreview} alt="" />
      </AnimationBox>
    </div>
  );
}

export default ApiSection;