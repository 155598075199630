import { urlSuffixForEnvironment } from '../utils'
import { post_request } from './firestore'
import config from '../config.json'
import firebase from './firebase'
import 'firebase/functions'

// firebase.functions().useEmulator('localhost', 5001)
// firebase.app().functions('europe-west1').useEmulator('localhost', 5001)

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment)}`

const sendEmail = async (sender, recipient, subject, text, documentName, documentData, attachments) => {
  return await post_request(`${baseUrl}/send_email_v2`, {
    sender,
    recipient,
    subject,
    text,
    documentName,
    documentData,
    attachments
  })
}

const sendSignatureRequest = async (data) => {
  return await post_request(`${baseUrl}/signature_request`, data);
}

const requestUploadAddress = async (teamId) => {
  const requestFunction = firebase.app().functions('europe-west1').httpsCallable("request_upload_address")
  const res = await requestFunction({
    environment: config.environment,
    team: teamId
  })
  return res.data
}

const teamInvite = async (data) => {
  return await post_request(`${baseUrl}/team_invite`, data);
}

const joinTeam = async (data) => {
  return await post_request(`${baseUrl}/join_team`, data);
}

// get number of docs being generated on jurisur
const get_jurisur_number_of_generated_docs = async () => {
  const fetchFunction = firebase.app().functions('europe-west1').httpsCallable('fetchJurisurDocumentGenerationNumber')
  let response = await fetchFunction({
    environment: 'production'
  })

  return response.data
}

export {
  sendEmail,
  sendSignatureRequest,
  requestUploadAddress,
  teamInvite,
  joinTeam,
  get_jurisur_number_of_generated_docs
} 