import React, { useContext, useEffect } from 'react'

import Header from '../sections/landing/Header'
import Footer from '../sections/landing/Footer'
import PageLoader from '../UI/PageLoader'
import { GlobalContext, FeatureContext } from '../../context'
import { get_number_of_generated_docs } from '../../services/analytics'
import { get_jurisur_number_of_generated_docs } from '../../services/functions'
import firebase from '../../services/firebase'
import 'firebase/app'
import 'firebase/firestore'

const LandingLayout = ({ children }) => {
  const { t, setNumberOfDocsGenerated, numberOfDocsGeneratedListener, setNumberOfDocsListener, setNumberOfDocsGeneratedFetched } = useContext(GlobalContext)
  const { fetchFeatures, featuresFetched } = useContext(FeatureContext)

  useEffect(() => {
    if(!numberOfDocsGeneratedListener) {
      setNumberOfDocsListener(() => {
        firebase.app().firestore().doc(`analytics/total`).onSnapshot((snapshot) => {
        const stats = snapshot.data()
        let num = 0
        if(stats.document_download) {
          num += stats.document_download
        }
        if(stats.document_preview) {
          num += stats.document_preview
        }
        setNumberOfDocsGeneratedFetched(true)
        setNumberOfDocsGenerated(num)
      }, (error) => {
        console.log('on snapshot error')
        console.log(error)
      })})
    }
  }, [numberOfDocsGeneratedListener, setNumberOfDocsListener, setNumberOfDocsGenerated, setNumberOfDocsGeneratedFetched])

  // Fetch features
  useEffect(() => {
    if(!featuresFetched) {
      // console.log('fetch features***')
      fetchFeatures()
    }
  }, [featuresFetched, fetchFeatures])

  if(!featuresFetched) {
    return <PageLoader />
  }

  return (
    <div className="landing">
      <Header t={t} />
      <main>
        {children}
      </main>
      <Footer t={t} />
    </div>
  )
}

export default LandingLayout 