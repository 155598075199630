import React, { createContext, useReducer } from 'react'

import { fetch_features } from '../services/features'

const GET_FEATURES = 'GET_FEATURES'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_FEATURES:
      return {
        ...state,
        features: action.payload,
        featuresFetched: true
      }
    default:
      return state
  }
}

export const FeatureContext = createContext()

const FeatureProvider = ({ children }) => {
  const initialState = {
    features: [],
    featuresFetched: false
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch articles
  const fetchFeatures = async () => {
    try {
      const res = await fetch_features()
      let sortedArr = []
      if(res['order'] && res['order'].order) {
        let order = res['order'].order 
        order.forEach(id => {
          sortedArr.push({id, ...res[id]})
        })
      }else {
        for(let id in res) {
          if(id !== 'order') {
            sortedArr.push({ id, ...res[id] })
          }
        }
      }
      dispatch({
        type: GET_FEATURES,
        payload: sortedArr
      })
    } catch (err) {
      console.log(err)
    }
  }

  return <FeatureContext.Provider value={{
    features: state.features,
    featuresFetched: state.featuresFetched,
    fetchFeatures
  }}>
    { children }
  </FeatureContext.Provider>
}

export default FeatureProvider