import React from 'react';

import AnimationBox from '../../UI/AnimationBox'
import privacyIcon from '../../../assets/images/privacy_icon.png';

const labelsArr = [
  'ISO 27001',
  'ISO 27017',
  'ISO 27018',
  'SOC 1, SOC 2, and SOC 3',
];

const labelsArr2 = [
  'AES-256',
  'HTPPS',
];

const labelsArr3 = [
  'RGPD',
  'Privacy by design',
];

const Security = ({ t }) => {
  return (
    <div className="landing-security-wrapper">
      <div className="landing-container">
        <div className="landing-security">
          <div className="landing-security__left">
            <AnimationBox>
              <div className="landing-security__box">
                <div className="landing-security__box_inner">
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="circle"></div>
                </div>
                <div className="landing-security__box_inner">
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="circle"></div>
                </div>
                <div className="landing-security__box_inner">
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="round-rec"></div>
                  <div className="circle"></div>
                </div>
              </div>
            </AnimationBox>
          </div>
          <div className="landing-security__right">
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.security_title_1')}</h2>
            </AnimationBox>
            <AnimationBox>
              <div className="landing-security__label">{t('landing.security_main_label_1')}</div>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.security_text_1')}</p>
            </AnimationBox>
            <AnimationBox>
              <div className="landing-security__labels">
                {labelsArr.map((label, i) => (<div key={i}>{label}</div>))}
              </div>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.security_text_2')}</p>
            </AnimationBox>
            <AnimationBox>
              <ul>
                <li>{t('landing.security_list_item_1')}</li>
                <li>{t('landing.security_list_item_2')}</li>
                <li>{t('landing.security_list_item_3')}</li>
              </ul>
            </AnimationBox>
          </div>
        </div>
        <div className="landing-security">
          <div className="landing-security__left">
            <AnimationBox>
              <img src={privacyIcon} alt=""/>
            </AnimationBox>
          </div>
          <div className="landing-security__right">
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.security_title_2')}</h2>
            </AnimationBox>
            <AnimationBox>
              <div className="landing-security__label">{t('landing.security_main_label_2')}</div>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.security_text_3')}</p>
            </AnimationBox>
            <AnimationBox>
              <div className="landing-security__labels">
                {labelsArr2.map((label, i) => (<div key={i}>{label}</div>))}
              </div>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.security_text_4')}</p>
            </AnimationBox>
            <AnimationBox>
              <div className="landing-security__labels">
                {labelsArr3.map((label, i) => (<div key={i}>{label}</div>))}
              </div>
            </AnimationBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;