import React, { useContext } from 'react';

import AnimationBox from '../../UI/AnimationBox'
import speedometer from '../../../assets/images/speedometer.svg';
import heading from '../../../assets/images/take_control_heading.svg';
import headingEn from '../../../assets/images/take_control_heading_en.svg';
import headingNl from '../../../assets/images/take_control_heading_nl.svg';
import { GlobalContext } from '../../../context'

const TakeControl = ({ t }) => {
  const { selectedLang } = useContext(GlobalContext)

  let titleImageSrc = heading

  if(selectedLang === 'en') {
    titleImageSrc = headingEn
  }else if(selectedLang === 'nl') {
    titleImageSrc = headingNl
  }

  return (
    <div className="landing-take-control">
      <div className="landing-container">
        <AnimationBox>
          <img src={speedometer} alt="" className="speedometer"/>
        </AnimationBox>
        <AnimationBox>
          <div className='take-control-label'>
            <span>{t('landing.take_control_title_1')}</span>
            <span>{t('landing.take_control_title_2')}</span>
          </div>
          {/* <img src={titleImageSrc} alt={t('landing.take_control_title')} className="heading"/> */}
        </AnimationBox>
        {/* <svg className="gradient-heading">
          <defs>
            <linearGradient x1="0.40793988" y1="0.10876854" x2="0.5" y2="1" id="take_control_gradient">
              <stop offset="0" stop-color="#F78E64" />
              <stop offset="1" stop-color="#ED5534" />
            </linearGradient>
          </defs>
          <text fill="url(#take_control_gradient)" x={0} y={101}>
            <tspan x={0} y={40}>{t('landing.take_control_title_1')}</tspan>
            <tspan x={231} y={96}>{t('landing.take_control_title_2')}</tspan>
          </text>
        </svg> */}
        <div className="landing-take-control__text-wrap">
          <AnimationBox>
            <p>{t('dashboard.main_title')}</p>
          </AnimationBox>
        </div>
      </div>
    </div>
  );
}

export default TakeControl;