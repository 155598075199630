import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/index.scss';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { GlobalProvider, HelpProvider, FeatureProvider, BlogProvider } from './context'

Bugsnag.start({
  apiKey: '2ee48cc1a20f751a5d85dbd24ab54739',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GlobalProvider>
        <HelpProvider>
          <FeatureProvider>
            <BlogProvider>
              <App />       
            </BlogProvider>
          </FeatureProvider>
        </HelpProvider>
      </GlobalProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);