import axios from 'axios'
import { urlSuffixForEnvironment } from '../utils'
import firebase from './firebase'
import config from '../config.json'

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment)}`

const log_event = async (events) => {
  if(!events) {
    return
  }  
  return await post_request(`${baseUrl}/log_event`, { events })
}

const post_request = async (url, data) => {
  const token = await firebase.auth().currentUser.getIdToken()
  let response = await axios.post(`${url}?id_token=${token}`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data
}

// get number of docs being generated
const get_number_of_generated_docs = async () => {
  try {
    const res = await firebase.firestore().collection('environments').doc('production').get()
    return res.data()
  } catch (err) {
    console.log('err getting analytics', err)
  }
}

export {
  log_event,
  get_number_of_generated_docs
}