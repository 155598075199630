import firebase from './firebase'

// Fetch articles
const fetch_features = async () => {
  try {
    const res = await firebase.firestore().collection('landing_features').get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch articles error', err)
  }
}

export {
  fetch_features
}