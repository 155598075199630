export const SEARCH_HISTORY_LS_KEY = 'jurisur_search_history';

export const CONVERSION_RATES = {
  euro: {
    pound: 0.89,
    dollar: 1.22
  },
  dollar: {
    pound: 0.73,
    euro: 0.82
  },
  pound: {
    euro: 1.12,
    dollar: 1.37
  },
}

export const CARBON_COOKIE = 'carbon_cookie_consent';
export const CARBON_SORT_LS = 'carbon_sort';
export const GA_ID = 'EC4V1350M7';

// todo - placeholders to allow build to succeed
export const CARBON_PAGINATION_LS = ''
export const CARBON_TEAM_COLUMNS_ORDER_LS = ''
export const CARBON_SELECTED_TEAM_LS = ''
export const CARBON_TEAM_PAGINATION_LS = ''
export const CARBON_TEAM_COLUMNS_LS = ''
export const CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS = ''
export const CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS = ''
export const CARBON_TEAM_SORT_LS = ''