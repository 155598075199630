import React, { useContext, useEffect } from 'react';

import Intro from '../sections/landing/Intro';
import Partners from '../sections/landing/Partners';
import AnalysisSection from '../sections/landing/AnalysisSection';
import Deadlines from '../sections/landing/Deadlines';
import StatusAndSignature from '../sections/landing/StatusAndSignature';
import ApiSection from '../sections/landing/ApiSection';
import Toolbox from '../sections/landing/Toolbox';
import Security from '../sections/landing/Security';
import TryCarbon from '../sections/landing/TryCarbon';
import YourDocuments from '../sections/landing/YourDocuments';
import DocumentGeneration from '../sections/landing/DocumentGeneration';
import TakeControl from '../sections/landing/TakeControl';
import UseOnAllDevices from '../sections/landing/UseOnAllDevices';
import ModelingYourDocuments from '../sections/landing/ModelingYourDocuments';

const Landing = ({ t }) => {
  return (
    <>
      <Intro t={t} />
      <Partners t={t} />
      <ModelingYourDocuments t={t} />
      <YourDocuments t={t} />
      <DocumentGeneration t={t} />
      <StatusAndSignature t={t} />
      <TakeControl t={t} />
      <AnalysisSection t={t} />
      <Deadlines t={t} />
      <ApiSection t={t} />
      <Toolbox t={t} />
      <Security t={t} />
      <UseOnAllDevices t={t} />
      <TryCarbon t={t} />
    </>
  );
}

export default Landing;