import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'

const variants = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, y: -50 },
}

const AnimationBox = ({ children, boxVariants = null, className = '' }) => {
  const animation = useAnimation()
  const [ref, inView] = useInView()

  // When scroll to element animate it
  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <motion.div
      ref={ref}
      animate={animation}
      initial="hidden"
      variants={boxVariants ? boxVariants : variants}
      className={`animation-box ${className}`}
    >
      { children }
    </motion.div>
  )
}

export default AnimationBox 