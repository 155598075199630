import { useEffect } from 'react'
import { Close, CalendarToday, Language } from '@material-ui/icons'
import moment from 'moment'
import Lottie from 'lottie-react'

import Loader from '../../../UI/Loader'
import loadingAnimation from '../../../../assets/animations/loading.json'

const DocumentsPreview = ({ opened = false, onClose, onOpenSingleDoc, singleOpened = false, templates, t, loading }) => {

  // Disable body scroll when this component is opened
  useEffect(() => {
    if(opened) {
      window.document.body.style.overflowY = 'hidden'
    }

    return () => {
      if(opened) {
        window.document.body.style.overflowY = null
      }
    }
  }, [opened])

  return (
    <div className={`documents-preview-panel ${opened ? 'opened' : ''} ${singleOpened ? 'extend' : ''}`}>
      <div className="documents-preview-panel__head">
        <div className="documents-preview-panel__close" onClick={onClose}><Close /></div>
        <h2>{t('landing.documents_preview_title')}</h2>
      </div>
      <div className="documents-preview-panel__body u-custom-scrollbar">
        {/* <h2>{t('landing.documents_preview_subtitle')}</h2> */}
        <div className={`documents-wrapper ${loading ? 'documents-wrapper--loading' : ''}`}>
          { loading && (
            <div className="loading-wrapper">
              <Lottie animationData={loadingAnimation} />
              <p>{t('landing.documents_loading_text')}</p>
            </div>
          )}
          { !loading && templates.map(template => {
            return (
              <div key={`template_${template.id}`} className="document-box" onClick={() => onOpenSingleDoc(template)}>
                <div className="document-box__main">
                  <h3>{template.name}</h3>
                  <p>{template.description || ''}</p>
                </div>
                <div className="document-box__foot">
                  <p><CalendarToday />{ moment(template.meta.updated).format('DD/MM/YY')}</p>
                  {/* todo use library language */}
                  <p><Language />{ template.language }</p>
                </div>
              </div>
            )
          }
          )}

        </div>
      </div>
    </div>
  )
}

export default DocumentsPreview