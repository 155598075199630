import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import LandingLayout from '../components/layouts/LandingLayout'
import Landing from '../components/pages/Landing'
import NotFound from '../components/pages/NotFound'
import Blog from '../components/pages/Blog'
import BlogSingle from '../components/pages/BlogSingle'
import Help from '../components/pages/Help'
import HelpArticle from '../components/pages/HelpArticle'
import HelpCategory from '../components/pages/HelpCategory'
import News from '../components/pages/News'
import NewsArticle from '../components/pages/NewsArticle'
import ContactUs from '../components/pages/ContactUs'
import Feature from '../components/pages/Feature'
import DPO from '../components/pages/DPO'

import { GlobalContext } from '../context'

const Routes = () => {
  const { t } = useContext(GlobalContext)

  return (
    <Switch>
      <Route path="/" exact>
        <LandingLayout>
          <Landing t={t} />
        </LandingLayout>
      </Route>
      {/* <Route path="/blog" exact>
        <LandingLayout>
          <Blog t={t} />
        </LandingLayout>
      </Route>
      <Route path="/blog-single/:id" exact>
        <LandingLayout>
          <BlogSingle t={t} />
        </LandingLayout>
      </Route>
      <Route path="/help" exact>
        <Help t={t} />
      </Route>
      <Route path="/help-article/:id" exact>
        <HelpArticle t={t} />
      </Route>
      <Route path="/help-category/:id" exact>
        <HelpCategory t={t} />
      </Route>
      <Route path="/news" exact>
        <News t={t} />
      </Route>
      <Route path="/news-article/:id" exact>
        <NewsArticle t={t} />
      </Route>
      <Route path="/contact" exact>
        <ContactUs t={t} />
      </Route>
      <Route path="/feature/:slug" exact>
        <LandingLayout>
          <Feature t={t} />
        </LandingLayout>
      </Route> */}
      <Route path="/dpo" exact>
        <LandingLayout>
          <DPO t={t} />
        </LandingLayout>
      </Route>
      <Route path="*">
        <LandingLayout>
          <NotFound />
        </LandingLayout>
      </Route>
    </Switch>
  )
}

export default Routes