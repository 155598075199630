import { Close, CalendarToday, Language } from '@material-ui/icons'
import moment from 'moment'

const SingleDocPreview = ({ opened = false, onClose, template }) => {
  return (
    <div className={`documents-preview-panel single ${opened ? 'single-opened' : ''}`}>
      <div className="documents-preview-panel__head">
        <div className="documents-preview-panel__close" onClick={onClose}><Close /></div>
        <h2>{template?.name}</h2>
      </div>
      <div className="documents-preview-panel__body-single u-custom-scrollbar">
        <div className="single-doc-preview">
          <div className="preview-page">preview here</div>
          <div className="preview-page">preview here</div>
        </div>
        <div className="single-doc-info">
          {/* <div className="description">The Seagate contracting party identified in your Lyve Services agreement (“Seagate”) and you (“Company”) (each a “party” and collectively the “parties”) have entered into a Lyve Services agreement (the “Agreement”) under which Seagate may Proces</div> */}
          <div className="description">{template?.description || ''}</div>
          <div className="single-doc-info__foot">
            <p><CalendarToday />{moment(template?.meta.updated).format('DD/MM/YY')}</p>
            <p><Language />{ template?.language }</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleDocPreview