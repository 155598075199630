import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import AnimationBox from '../../UI/AnimationBox'
import Loader from '../../UI/Loader'
import { GlobalContext, FeatureContext } from '../../../context'

const Toolbox = ({ t }) => {
  const { selectedLang } = useContext(GlobalContext)
  const { features, featuresFetched } = useContext(FeatureContext)
  const history = useHistory()

  // On feature click
  const handleGoToFeature = (slug) => {
    // history.push(`/feature/${slug}`)
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  
  return (
    <div className="landing-toolbox">
      <div className="landing-container">
        <div className="landing-toolbox__head">
          <AnimationBox>
            <div className="landing-label landing-label--6 landing-label--sm"><span>{t('landing.toolbox_label')}</span></div>
          </AnimationBox>
          <AnimationBox>
            <h2 className="landing-h2">{t('landing.toolbox_title')}</h2>
          </AnimationBox>
          <AnimationBox>
            <p className="landing-p">{t('landing.toolbox_text')}</p>
          </AnimationBox>
        </div>
        {!featuresFetched && <div className="loader-wrapper"><Loader small normal primary /></div>}
        <div className="landing-toolbox__body">
          {features.map((feature, idx) => {
            const { icon, icon_type, title, id, show_in_section } = feature
            let iconClass = 'material-icons'
            if(icon_type !== 'filled') {
              iconClass = `material-icons-${icon_type}`
            }
            if(!show_in_section) return null
            return (
              <AnimationBox 
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: idx * 0.1 } },
                  hidden: { opacity: 0, x: 50 },
                }}
                key={id}
              >
                <div className="landing-icon-box" onClick={() => handleGoToFeature(feature.slug)}>
                  <div className="landing-icon-box__circle"><span className={iconClass}>{icon}</span></div>
                  <p>{title[selectedLang]}</p>
                </div>
              </AnimationBox>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Toolbox;