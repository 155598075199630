import React from 'react';

import AnimationBox from '../../UI/AnimationBox'

const AnalysisSection = ({ t }) => {
  return(
    <div className="landing-analysis-section-wrapper">
      <div className="landing-container landing-container--analysis-section">
        <div className="landing-analysis-section">
          <div className="landing-analysis-section__col">
            <AnimationBox>
              <div className="landing-label landing-label--1"><span>{t('landing.reports_label')}</span></div>
            </AnimationBox>
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.reports_title')}</h2>
            </AnimationBox>
            <div className="landing-analysis-section__col_text">
              <AnimationBox>
                <p>{t('landing.reports_text_1')}</p>
              </AnimationBox>
              <AnimationBox>
                <p>{t('landing.reports_text_2')}</p>
              </AnimationBox>
            </div>
            <div className="landing-analysis-section__col_chart">
              <div className="landing-analysis-section__col_chart-col">
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-rec" style={{height: '154px'}}>
                  <AnimationBox
                    boxVariants={{
                      visible: { height: 114, transition: { duration: 0.25, delay: 0.25 } },
                      hidden: { height: 0 },
                    }}
                  >
                    <div style={{height: '100%'}}></div>
                  </AnimationBox>
                </div>
              </div>
              <div className="landing-analysis-section__col_chart-col">
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-rec" style={{height: '101px'}}>
                  <AnimationBox
                    boxVariants={{
                      visible: { height: 84, transition: { duration: 0.25, delay: 0.5 } },
                      hidden: { height: 0 },
                    }}
                  >
                    <div style={{height: '100%'}}></div>
                  </AnimationBox>
                </div>
              </div>
              <div className="landing-analysis-section__col_chart-col">
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-rec" style={{height: '233px'}}>
                  <AnimationBox
                    boxVariants={{
                      visible: { height: 201, transition: { duration: 0.25, delay: 0.75 } },
                      hidden: { height: 0 },
                    }}
                  >
                    <div style={{height: '100%'}}></div>
                  </AnimationBox>
                </div>
              </div>
              <div className="landing-analysis-section__col_chart-col">
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-line"></div>
                <div className="landing-analysis-section__col_chart-col-rec" style={{height: '168px'}}>
                  <AnimationBox
                    boxVariants={{
                      visible: { height: 146, transition: { duration: 0.25, delay: 1 } },
                      hidden: { height: 0 },
                    }}
                  >
                    <div style={{height: '100%'}}></div>
                  </AnimationBox>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-analysis-section__col">
            <AnimationBox>
              <div className="landing-label landing-label--2"><span>{t('landing.segmentation_label')}</span></div>
            </AnimationBox>
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.segmentation_title')}</h2>
            </AnimationBox>
            <div className="landing-analysis-section__col_text">
              <AnimationBox>
                <p>{t('landing.segmentation_text_1')}</p>
              </AnimationBox>
              <AnimationBox>
                <p>{t('landing.segmentation_text_2')}</p>
              </AnimationBox>
            </div>
            <div className="landing-tags">
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.1 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_1')}</span><span className="num">8</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.2 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_2')}</span><span className="num">7</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.3 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_3')}</span><span className="num">14</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.4 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_4')}</span><span className="num">3</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.5 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_5')}</span><span className="num">23</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.6 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_6')}</span><span className="num">18</span></div>
              </AnimationBox>
              <AnimationBox
                boxVariants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.7 } },
                  hidden: { opacity: 0, x: 20 },
                }}
              >
                <div className="tag"><span className="text">{t('landing.segmentation_tag_7')}</span><span className="num">9</span></div>
              </AnimationBox>
            </div>
          </div>
        </div>

        <div className="landing-analysis-section__bg-1"></div>
        <div className="landing-analysis-section__bg-2"></div>
      </div>
    </div>
  );
}

export default AnalysisSection;