import React, { useContext } from 'react';

import AnimationBox from '../../UI/AnimationBox'
import folderGray from '../../../assets/images/folder-gray.svg';
import folderGreen from '../../../assets/images/folder-green.svg';
import folderBlue from '../../../assets/images/folder-blue.svg';
import signature from '../../../assets/images/signature_2.png';
import folderImg1 from '../../../assets/images/folder-img-1.jpg';
import folderImg2 from '../../../assets/images/folder-img-2.jpg';
import folderImg3 from '../../../assets/images/folder-img-3.jpg';
import folderImg4 from '../../../assets/images/folder-img-4.jpg';
import { GlobalContext } from '../../../context'
import Loader from '../../UI/Loader'

const StatusAndSignature = ({ t }) => {
  const { numberOfDocsGenerated, numberOfDocsGeneratedFetched } = useContext(GlobalContext)

  return (
    <div className="landing-status-and-signature-wrapper">
      <div className="landing-container">
        <div className="landing-status-and-signature">
          <AnimationBox>
            <div className="number-of-documents">
              <p>
                <span className='emojis'>🗄🚀</span> 
                {t('landing.number_of_docs')}: 
                <span className='number'>{numberOfDocsGeneratedFetched ? numberOfDocsGenerated : <Loader primary mini normal />}</span>
              </p>
            </div>
          </AnimationBox>
          <div className="landing-status-and-signature__top">
            <AnimationBox>
              <div className="landing-label landing-label--9 landing-label--sm"><span>{t('landing.signature_label')}</span></div>
            </AnimationBox>
            <AnimationBox>
              <h2>{t('dashboard.signature')}</h2>
            </AnimationBox>
            <AnimationBox>
              <p>{t('landing.signature_text_1')}</p> 
            </AnimationBox>
          </div>
          <div className="landing-status-and-signature__bottom">
            <AnimationBox
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <div className="landing-folder-box landing-folder-box--1">
                <div className="landing-folder-box__icon">
                  <img src={folderGray} alt=""/>
                </div>
                <p><span>{t('status.draft')}</span></p>
                <div className="user">
                  <img src={folderImg1} alt=""/>
                </div>
              </div>
            </AnimationBox>
            <AnimationBox
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <div className="landing-folder-box landing-folder-box--2">
                <div className="landing-folder-box__icon">
                  <img src={folderGreen} alt=""/>
                </div>
                <p><span>{t('status.validated')}</span></p>
                <div className="user">
                  <img src={folderImg2} alt=""/>
                </div>
              </div>
            </AnimationBox>
            <AnimationBox
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.75 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <div className="landing-folder-box landing-folder-box--3">
                <div className="landing-folder-box__icon">
                  <img src={folderBlue} alt=""/>
                </div>
                <p><span>{t('status.signed')}</span></p>
                <div className="user">
                  <img src={folderImg4} alt=""/>
                  <img src={folderImg3} alt=""/>
                </div>
              </div>
            </AnimationBox>
          </div>
        </div>
      </div>

      <AnimationBox 
        className="signature"
        boxVariants={{
          visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1 } },
          hidden: { opacity: 0, x: 50 },
        }}
      >
        <img src={signature} alt=""/>
      </AnimationBox>
    </div>
  );
}

export default StatusAndSignature;