import { useContext, useEffect, useState } from 'react'
import { CheckCircleRounded } from '@material-ui/icons'

import Intro from '../sections/landing/dpo/Intro'
import TryCarbon2 from '../sections/landing/TryCarbon2'
import DocumentsPreview from '../sections/landing/dpo/DocumentsPreview'
import SingleDocPreview from '../sections/landing/dpo/SingleDocPreview'
import highlighted_img_1 from '../../assets/images/highlighted_img_1.png'
import highlighted_img_2 from '../../assets/images/highlighted_img_2.png'
import highlighted_img_3 from '../../assets/images/highlighted_img_3.png'
import highlighted_img_4 from '../../assets/images/highlighted_img_4.png'
import highlighted_img_5 from '../../assets/images/highlighted_img_5.png'
import { fetch_template_previews } from '../../services/firestore'
import { GlobalContext } from '../../context'

// this page name will probably change and there will probably be more pages like this one
const DPO = ({ t }) => {

  const { selectedLang, librariesFetched, setLibrariesFetched, libraries, setLibraries } = useContext(GlobalContext)

  const [showDocsPreview, setShowDocsPreview] = useState(false)
  const [selectedLibrary, setSelectedLibrary] = useState([])
  const [singleDocPreviewTemplate, setSingleDocPreviewTemplate] = useState(null)
  const [librariesLoading, setLibrariesLoading] = useState(false)

  useEffect(() => {
    // if(Object.keys(libraries).length > 0) {
    //   return
    // }
    fetchLibraries()
  }, [selectedLang])

  const fetchLibraries = async () => {
    setLibrariesLoading(true)
    // let languages = ['fr', 'nl', 'en']
    // let promises = []
    // for(let lang of languages) {
    //   promises.push(fetchLibrary(lang))
    // }
    // let res = await Promise.all(promises)
    // let libs = {}
    // for(let libObj of res) {
    //   if(!libObj.lang) {
    //     continue
    //   }
    //   libs[libObj.lang] = libObj.lib
    // }
    if(!librariesFetched[selectedLang]) {
      const res = await fetchLibrary(selectedLang)
      if(res.lang && res.lib) {
        setLibraries({...libraries, [res.lang]: res.lib})
        setLibrariesFetched({...librariesFetched, [selectedLang]: true})
      }
    } 
    // setLibraries(libs)
    setLibrariesLoading(false)
  }

  const fetchLibrary = async (lang) => {
    let langSuffix = lang !== 'fr' ? `_${lang}` : ''
    const response = await fetch_template_previews(`dpo_library${langSuffix}`)
     if(response.error) {
      console.log(response.error)
      return
    }
    let lib = []
    for(let key in response) {
      lib.push({
        id: key,
        ...response[key]
      })
    }
    return { lang, lib }
  }

  useEffect(() => {
    if(!libraries[selectedLang]) {
      return
    }
    setSelectedLibrary(libraries[selectedLang])
  }, [libraries, selectedLang])

  // On open docs preview
  const handleOpenDocsPreview = () => {
    setShowDocsPreview(true)
  }

  // On close docs preview
  const handleCloseDocsPreview = () => {
    setShowDocsPreview(false)
  }

  // On open single doc preview
  const handleOpenSingleDocPreview = (template) => {
    return 
    // setSingleDocPreviewTemplate(template)
  }

  // On close single doc preview
  const handleCloseSingleDocPreview = () => {
    setSingleDocPreviewTemplate(null)
  }

  return (
    <>
      <Intro t={t} onDocumentsPreviewOpen={handleOpenDocsPreview} />
      <div className="highlights-section">
        <div className="landing-container">
          <div className="highlighted-area">
            <div className="highlighted-area__img">
              <img src={highlighted_img_1} alt="" />
            </div>
            <div className="highlighted-area__content">
              <h2>{t('landing.dpo_highlighted_area_1_title')}</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: t('landing.dpo_highlighted_area_1_desc') }}></div>
              <ul>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_1_li_1')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_1_li_2')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_1_li_3')} </li>
              </ul>
            </div>
          </div>

          <div className="highlighted-area highlighted-area--2 center-img">
            <div className="highlighted-area__img">
              <img src={highlighted_img_2} alt="" />
            </div>
            <div className="highlighted-area__content">
              <h2>{t('landing.dpo_highlighted_area_2_title')}</h2>
              <div className="description">{t('landing.dpo_highlighted_area_2_desc')} </div>
              <ul>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_2_li_1')} </li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_2_li_2')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_2_li_3')}</li>
              </ul>
            </div>
          </div>

          <div className="highlighted-area">
            <div className="highlighted-area__img">
              <img src={highlighted_img_3} alt="" />
            </div>
            <div className="highlighted-area__content">
              <h2>{t('landing.dpo_highlighted_area_3_title')}</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: t('landing.dpo_highlighted_area_3_desc') }}></div>
              <ul>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_3_li_1')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_3_li_2')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_3_li_3')}</li>
              </ul>
            </div>
          </div>

          <div className="highlighted-area highlighted-area--2 with-absolute-img">
            <div className="highlighted-area__img">
              <img src={highlighted_img_4} alt="" className="absolute" />
            </div>
            <div className="highlighted-area__content">
              <h2>{t('landing.dpo_highlighted_area_4_title')}</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: t('landing.dpo_highlighted_area_4_desc') }}></div>
              <ul>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_4_li_1')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_4_li_2')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_4_li_3')}</li>
              </ul>
            </div>
          </div>

          <div className="highlighted-area with-absolute-img highlighted-area--last">
            <div className="highlighted-area__img">
              <img src={highlighted_img_5} alt="" className="absolute" />
            </div>
            <div className="highlighted-area__content">
              <h2>{t('landing.dpo_highlighted_area_5_title')}</h2>
              <div className="description">{t('landing.dpo_highlighted_area_5_desc')}</div>
              <ul>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_5_li_1')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_5_li_2')}</li>
                <li><CheckCircleRounded /> {t('landing.dpo_highlighted_area_5_li_3')}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <TryCarbon2 t={t} className="landing-try--spacing-top" />

      {showDocsPreview && <div className="documents-preview-overlay" onClick={handleCloseDocsPreview}></div>}
      <DocumentsPreview 
        templates={selectedLibrary}
        opened={showDocsPreview} 
        onClose={handleCloseDocsPreview} 
        onOpenSingleDoc={handleOpenSingleDocPreview} 
        singleOpened={Boolean(singleDocPreviewTemplate)}
        loading={librariesLoading}
        t={t}
      />
      {Boolean(singleDocPreviewTemplate) && <div className="documents-preview-overlay documents-preview-overlay--2" onClick={handleCloseSingleDocPreview}></div>}
      <SingleDocPreview 
        template={singleDocPreviewTemplate}
        opened={Boolean(singleDocPreviewTemplate)}
        onClose={handleCloseSingleDocPreview} 
      />
    </>
  )
}

export default DPO 