const LinkedIn = () => {
  return (
    <span className="svg-icon">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 455.731 455.731" xmlSpace="preserve">
        <g>
          <rect x="0" y="0" style={{fill: "#19C6E6" }} width="455.731" height="455.731"/>
          <g>
            <path style={{fill: "##091937"}} d="M107.255,69.215c20.873,0.017,38.088,17.257,38.043,38.234c-0.05,21.965-18.278,38.52-38.3,38.043
              c-20.308,0.411-38.155-16.551-38.151-38.188C68.847,86.319,86.129,69.199,107.255,69.215z"/>
            <path style={{fill: "##091937"}} d="M129.431,386.471H84.71c-5.804,0-10.509-4.705-10.509-10.509V185.18
              c0-5.804,4.705-10.509,10.509-10.509h44.721c5.804,0,10.509,4.705,10.509,10.509v190.783
              C139.939,381.766,135.235,386.471,129.431,386.471z"/>
            <path style={{fill: "##091937"}} d="M386.884,241.682c0-39.996-32.423-72.42-72.42-72.42h-11.47c-21.882,0-41.214,10.918-52.842,27.606
              c-1.268,1.819-2.442,3.708-3.52,5.658c-0.373-0.056-0.594-0.085-0.599-0.075v-23.418c0-2.409-1.953-4.363-4.363-4.363h-55.795
              c-2.409,0-4.363,1.953-4.363,4.363V382.11c0,2.409,1.952,4.362,4.361,4.363l57.011,0.014c2.41,0.001,4.364-1.953,4.364-4.363
              V264.801c0-20.28,16.175-37.119,36.454-37.348c10.352-0.117,19.737,4.031,26.501,10.799c6.675,6.671,10.802,15.895,10.802,26.079
              v117.808c0,2.409,1.953,4.362,4.361,4.363l57.152,0.014c2.41,0.001,4.364-1.953,4.364-4.363V241.682z"/>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default LinkedIn