import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Cookies from './components/sections/Cookies';
import { isCookieAccepted, acceptCookies } from './utils';
import { GA_ID } from './constants';
import Routes from './routing';

// ReactGA.initialize('G-434M13VMPY');
// ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [cookiesChanged, setCookiesChanged] = useState(false)
  const [showCookies, setShowCookies] = useState(!isCookieAccepted())
  
  const [showTrackingCode, setShowTrackingCode] = useState(false)

  // On cookies update
  useEffect(() => {
    if(cookiesChanged) {
      setShowCookies(false)
    }
  }, [cookiesChanged])

  // Show tracking code if cookie tracking prop is set to true
  useEffect(() => {
    if(isCookieAccepted()?.tracking) {
      setShowTrackingCode(true)
    }
  }, [cookiesChanged])
  
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          {/* {showTrackingCode && <script async src={`https://www.googletagmanager.com/gtag/js?id=G-${GA_ID}/`}></script>}
          {showTrackingCode && <script>
            {
            `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-${GA_ID}');`
            }
          </script>} */}
          {showTrackingCode &&<script>
          {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2359184,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>}
        </Helmet>
        
        <Routes />

        {showCookies && <Cookies shouldAcceptCookies={acceptCookies} setCookiesChanged={setCookiesChanged} />}
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
