import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';

import AnimationBox from '../../UI/AnimationBox'
import dashboardPreview from '../../../assets/images/dashboard_preview_4.png';
import introImages from '../../../assets/images/intro_images.png'
import Button from '../../UI/Button';

const Intro = ({ t }) => {
  return (
    <div className="landing-intro">
      <div className="landing-container">
        <div className="landing-intro__left">
          <AnimationBox>
            <h1>{t('landing.intro_title')}</h1>
          </AnimationBox>
          <AnimationBox>
            <p className="subtitle">{t('landing.intro_text')}</p>
          </AnimationBox>
          <AnimationBox>
            <p>{t('landing.intro_text_2')}</p>
          </AnimationBox>
          <div className="landing-intro__actions">
            <AnimationBox>
              <Button 
                icon={<CallIcon />} 
                text={t('landing.demo')} 
                primary 
                onButtonClick={() => window.open('https://calendly.com/getcarbon', '_blank')}
                className="landing-primary landing-primary--lg"
              />
            </AnimationBox>
            {/* <Button 
              icon={<KeyboardArrowRightIcon />} 
              text={t('landing.start_now')} 
              primary    
              onButtonClick={() => window.location.href = 'https://app.getcarbon.ai/signup'} 
              className="landing-primary landing-primary--lg"
            /> */}
          </div>
          {/* <ul>
            <li><DoneIcon />{t('landing.check_1')}</li>
            <li><DoneIcon />{t('landing.check_2')}</li>
          </ul> */}
        </div>
      </div>
      <AnimationBox
        className="intro-images"
        boxVariants={{
          visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
          hidden: { opacity: 0, x: 50 },
        }}
      >
        <img src={introImages} alt="" />
      </AnimationBox>
    </div>  
  );
}

export default Intro;