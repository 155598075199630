import cookie from 'react-cookies'
import fr from './fr-copy.json'
import nl from './nl-copy.json'
import en from './en-copy.json'
import es from './es-copy.json'
import it from './it-copy.json'

const CARBON_LANG_KEY = 'carbon_selected_lang';

const source = {
  fr,
  nl,
  en,
  es,
  it
}

const languages = ['fr', 'en', 'nl', 'es', 'it']

// Current language
const currentLanguage = () => {
  let language = cookie.load(CARBON_LANG_KEY)
  let browserLang = navigator.language || navigator.userLanguage
  if(!language) {
    let langShort = browserLang.split('-')[0]
    if(languages.includes(langShort)) {
      language = langShort
    }else {
      language = languageFromLocale('en_UK').code
    }
  } 
  return language
}

// Language from locale
const languageFromLocale = (locale) => {
  switch(locale) {
    case 'fr_FR':
      return { long: 'Français', code: 'fr' }
    case 'en_UK':
      return { long: 'English', code: 'en' }
    case 'fr_BE':
      return { long: 'Français', code: 'fr' }
    case 'nl_BE':
      return { long: 'Nederlands', code: 'nl' }
    default:
      return { long: '', code: '' }
  }
}

const t = (key, params) => {
  const language = currentLanguage()
  const components = key.split('.')
  if(!source[language]) {
    return `Missing translation for ${key}`
  }
  const section = source[language][components[0]]
  if(!section) {
    return `Missing translation for ${key}`
  }
  let value = section[components[1]]
  if(!value) {
    return `Missing translation for ${key}`
  }
  if(!!params) {
    for(let p in params) {
      value = value.replace(`{{${p}}}`, params[p])
    }
  }
  return value
}

const setLanguage = (l) => {
  cookie.save(CARBON_LANG_KEY, l, { path: '/' })
}

const appLanguages = [
  { languageCode: 'en', labelShort: 'en' },
  { languageCode: 'fr', labelShort: 'fr' },
  { languageCode: 'nl', labelShort: 'nl' },
  { languageCode: 'es', labelShort: 'es' },
  { languageCode: 'it', labelShort: 'it' }
]

export default t
export { setLanguage, currentLanguage, CARBON_LANG_KEY, appLanguages }