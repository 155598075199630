import React from 'react';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import AnimationBox from '../../UI/AnimationBox'
import microsoftWordLogo from '../../../assets/images/microsoft_word_logo_2.png';
import pdfIcon from '../../../assets/images/pdf_icon_2.png';
import imgIcon from '../../../assets/images/img_icon_2.png';
import cloudUploadIcon from '../../../assets/images/backup_black.svg';

const boxesArr = [
  { icon: <img src={microsoftWordLogo} alt="Microsoft Word" /> },
  { icon: <img src={pdfIcon} alt="Microsoft Word" /> },
  { icon: <img src={imgIcon} alt="Microsoft Word" /> },
  { icon: <AlternateEmailIcon /> },
];

const YourDocuments = ({ t }) => {

  return (
    <div className="landing-your-documents">
      <div className="landing-container">
        <AnimationBox>
          <div className="landing-your-documents__top-icon">
            <img src={cloudUploadIcon} alt=""/>
          </div>
        </AnimationBox>
        <AnimationBox>
          <h2 className="landing-h2">{t('landing.your_documents_title')}</h2>
        </AnimationBox>
        <AnimationBox>
          <p className="landing-p">{t('landing.your_documents_text_1')}</p>
        </AnimationBox>
        <AnimationBox>
          <p className="landing-p">{t('landing.your_documents_text_2')}</p>
        </AnimationBox>
        <div className="landing-your-documents__boxes">
          {boxesArr.map((box, i) => (
            <AnimationBox 
              key={i}
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: i * 0.25 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <div className="landing-your-documents__boxes_col" key={i}>
                <div className="landing-your-documents__boxes_box">
                  {box.icon}
                </div>
              </div>
            </AnimationBox>
          ))}
        </div>
      </div>
    </div>
  );
}

export default YourDocuments;