import firebase from './firebase'

// Fetch articles
const fetch_articles = async () => {
  try {
    const res = await firebase.firestore().collection('help_articles').where('projects', 'array-contains', 'carbon').get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch articles error', err)
  }
}

// Fetch categories
const fetch_categories = async () => {
  try {
    const res = await firebase.firestore().collection('help_categories').where('projects', 'array-contains', 'carbon').get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch categories error', err)
  }
}

// Increcent readCount prop on article
const increment_article_read_count = async (id) => {
  const db = firebase.firestore()
  const increment = firebase.firestore.FieldValue.increment(1)
  const ref = db.collection('help_articles').doc(id)
  ref.update({ readCount: increment })
}

export {
  fetch_articles,
  fetch_categories,
  increment_article_read_count,
}