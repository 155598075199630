import React from 'react';

import word from '../../assets/images/word.svg';

const WordDocument = () => {
  return (
    <div className="word-document">
      <div className="word-document__body">
        <div className="word-document__line word-document__line--small"></div>
        <div className="word-document__line word-document__line--small"></div>
        <div className="word-document__line word-document__line--large"></div>
        <div className="word-document__line word-document__line--large"></div>
        <div className="word-document__line word-document__line--large"></div>
        <div className="word-document__line word-document__line--large"></div>
        <div className="word-document__line word-document__line--large"></div>
        <div className="word-document__line word-document__line--medium"></div>
        <div className="word-document__line word-document__line--medium"></div>
      </div>
      <img src={word} alt="" className="word-document__img"/>
    </div>
  );
}

export default WordDocument;