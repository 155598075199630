import React from 'react';

import AnimationBox from '../../UI/AnimationBox'
import Paper from '../../UI/Paper';
import infiniteIcon from '../../../assets/images/infinite_icon.svg';

const DocumentGeneration = ({ t }) => {
  return (
    <div className="landing-document-generation">
      <div className="landing-container">
        <AnimationBox>
          <img src={infiniteIcon} alt="" className="icon-top"/>
        </AnimationBox>
        <AnimationBox>
          <h2 className="landing-h2">{t('landing.doc_generation_title')}</h2>
        </AnimationBox>
        <AnimationBox>
          <p className="landing-p">{t('landing.doc_generation_text')}</p>
        </AnimationBox>
        <div className="landing-document-generation__papers">
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <Paper signature={false} />
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <Paper signature={false} />
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.75 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <Paper signature={false} />
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <Paper signature={false} />
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.25 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <Paper version2 signature={false} />
          </AnimationBox>
        </div>
      </div>
    </div>
  );
}

export default DocumentGeneration;