import React from 'react';
import { CalendarToday } from '@material-ui/icons';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';

import AnimationBox from '../../UI/AnimationBox'
import Button from '../../UI/Button';

const TryCarbon = ({ t }) => {

  return (
    <div className="landing-container">
      <div className="landing-try">
        <AnimationBox>
          <h2 className="landing-h2">{t('landing.try_title')}</h2>
        </AnimationBox>
        <AnimationBox>
          <p>{t('landing.try_text_1')}</p>
        </AnimationBox>
        <AnimationBox>
          <p>{t('landing.try_text_2')}</p>
        </AnimationBox>
        <div className="landing-try__actions">
          {/* <Button 
            icon={<KeyboardArrowRightIcon />} 
            text={t('landing.start_now')} 
            primary
            onButtonClick={() => window.location.href = 'https://app.getcarbon.ai/signup'} 
            className="landing-primary landing-primary--lg"
          /> */}
          <AnimationBox>
            <a href="mailto:hello@lawstud.io" className="button button--outline-primary">{t('landing.contact_us')}</a>
          </AnimationBox>
          <AnimationBox>
            <Button 
              icon={<CalendarToday />} 
              text={t('landing.demo')}  
              onButtonClick={() => window.open('https://calendly.com/getcarbon', '_blank')}   
              className="landing-primary landing-primary--lg"
              primary
            />
          </AnimationBox>
        </div>
        {/* <ul>
          <li><DoneIcon />{t('landing.check_1')}</li>
          <li><DoneIcon />{t('landing.check_2')}</li>
        </ul> */}
      </div>
    </div>
  );
}

export default TryCarbon;