import React, { createContext, useReducer } from 'react'

import { fetch_blog_articles, fetch_blog_categories } from '../services/blog'
import { sortArrayOfObjects } from '../utils'

const GET_ARTICLES = 'GET_ARTICLES'
const GET_CATEGORIES = 'GET_CATEGORIES'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_ARTICLES:
      return {
        ...state,
        blogArticles: action.payload.obj,
        blogArticlesArr: action.payload.arr,
        blogArticlesFetched: true
      }
    case GET_CATEGORIES:
      return {
        ...state,
        blogCategories: action.payload.obj,
        blogCategoriesArr: action.payload.arr,
        blogCategoriesFetched: true
      }
    default:
      return state
  }
}

export const BlogContext = createContext()

const BlogProvider = ({ children }) => {
  const initialState = {
    blogArticles: {},
    blogArticlesArr: [],
    blogArticlesFetched: false,
    blogCategories: {},
    blogCategoriesArr: [],
    blogCategoriesFetched: false,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch articles
  const fetchBlogArticles = async () => {
    try {
      const res = await fetch_blog_articles()
      let arr = []
      for(let id in res) {
        arr.push({ id, ...res[id] })
      }
      dispatch({
        type: GET_ARTICLES,
        payload: {
          obj: res,
          arr: sortArrayOfObjects(arr, 'created_at', 'asc'),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch categories
  const fetchBlogCategories = async () => {
    try {
      const res = await fetch_blog_categories()
      let arr = []
      for(let id in res) {
        arr.push({ id, ...res[id] })
      }
      dispatch({
        type: GET_CATEGORIES,
        payload: {
          obj: res,
          arr
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  return <BlogContext.Provider value={{
    blogArticles: state.blogArticles,
    blogArticlesArr: state.blogArticlesArr,
    blogArticlesFetched: state.blogArticlesFetched,
    blogCategories: state.blogCategories,
    blogCategoriesArr: state.blogCategoriesArr,
    blogCategoriesFetched: state.blogCategoriesFetched,
    fetchBlogArticles,
    fetchBlogCategories,
  }}>
    { children }
  </BlogContext.Provider>
}

export default BlogProvider