import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import { Lock, Call, ExpandMore, Menu } from '@material-ui/icons';
import { ClickAwayListener } from '@material-ui/core'

import logo from '../../../assets/images/carbon_logo.svg';
import Button from '../../UI/Button';
import AnimatedMenu from './animated_menu'
import MobileMenu from './MobileMenu'

import { appLanguages, setLanguage } from '../../../i18n';
import { isCookieAccepted } from '../../../utils'
import { GlobalContext } from '../../../context'

const Header = ({ t }) => {
  const { setSelectedLang, selectedLang } = useContext(GlobalContext)
  const [shrinkHeader, setShrinkHeader] = useState(false);
  const [showLangDropdown, setShowLangDropdown] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', bodyScrollHandler);
    
    return () => {
      window.removeEventListener('scroll', bodyScrollHandler);
    }
  });

  const bodyScrollHandler = (e) => {
    if(window.scrollY > 150) {
      setShrinkHeader(true);
    }else if(window.scrollY < 100){
      setShrinkHeader(false);
    }
  }

  // Set language
  const changeLanguage = (e, lang) => {
    e.preventDefault()
    setSelectedLang(lang)
    setShowLangDropdown(false)
    if(isCookieAccepted()) {
      setLanguage(lang)
    }
  }

  // On open mobile menu
  const handleOpenMobileMenu = (e) => {
    e.preventDefault()
    setShowMobileMenu(true)
  }

  // On open mobile close
  const handleCloseMobileMenu = (e) => {
    setShowMobileMenu(false)
  }

  return (
    <div className={shrinkHeader ? "landing-header sticky" : "landing-header"}>
      <div className="landing-container">
        <Link to="/dpo">
          <div className="landing-logo">
            <img src={logo} alt="Carbon"/>
            <p>Carbon</p>
          </div>
        </Link>
        {/* <AnimatedMenu t={t} /> */}
        <div className="landing-header__right">
          <a href="/#" className="menu-trigger" onClick={handleOpenMobileMenu}><Menu /></a>
          <a href={`https://app.getcarbon.ai/signin?lang=${selectedLang}`} className="link"><Lock />{t('auth.login')}</a>
          <Button 
            icon={<Call />} 
            text={t('landing.demo')} 
            primary
            className="landing-primary" 
            onButtonClick={() => window.open('https://calendly.com/getcarbon', '_blank')} 
          />
          {/* <Button 
            icon={<KeyboardArrowRightIcon />} 
            text={t('landing.try_for_free')} 
            primary 
            onButtonClick={() => window.location.href = 'https://app.getcarbon.ai/signup'} 
            className="landing-primary"
          /> */}
          {/* <a href="/#" className="mobile-menu-trigger"><MenuRoundedIcon /></a> */}

          {/* Language toggle */}
          <ClickAwayListener onClickAway={() => setShowLangDropdown(false)}>
            <div className="lang-wrapper">
              <div className="lang-wrapper__selected" onClick={() => setShowLangDropdown(!showLangDropdown)}>{selectedLang?.toUpperCase()} <ExpandMore /></div>
              {showLangDropdown && (
                <div className="lang-wrapper__dropdown">
                  { appLanguages.map((lang, li) =>
                    <a key={`language_option_${li}`} href="#/" className={selectedLang === lang.languageCode ? 'active' : ''} onClick={(e) => changeLanguage(e, lang.languageCode)}>{lang.labelShort.toUpperCase()}</a>
                  )}
                </div>
              )}
            </div>
          </ClickAwayListener>
          {/* <div className="languages">
            <a href="#/" className={selectedLang === 'fr' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'fr')}>FR</a>
            <a href="#/" className={selectedLang === 'nl' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'nl')}>NL</a>
            <a href="#/" className={selectedLang === 'en' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'en')}>EN</a>
          </div> */}
        </div>
      </div>

      {/* <MobileMenu onClose={handleCloseMobileMenu} show={showMobileMenu} /> */}
    </div>
  );
}

export default Header;

// import React, { useEffect, useState, useContext } from 'react';
// import { Link } from 'react-router-dom'
// import { CalendarToday, Call, ExpandMore, Menu } from '@material-ui/icons';
// import { ClickAwayListener } from '@material-ui/core'

// import logo from '../../../assets/images/carbon_logo.svg';
// import Button from '../../UI/Button';
// import AnimatedMenu from './animated_menu'
// import MobileMenu from './MobileMenu'

// import { setLanguage } from '../../../i18n';
// import { isCookieAccepted } from '../../../utils'
// import { GlobalContext } from '../../../context'

// const Header = ({ t }) => {
//   const { setSelectedLang, selectedLang } = useContext(GlobalContext)
//   const [shrinkHeader, setShrinkHeader] = useState(false);
//   const [showLangDropdown, setShowLangDropdown] = useState(false)
//   const [showMobileMenu, setShowMobileMenu] = useState(false)

//   useEffect(() => {
//     window.addEventListener('scroll', bodyScrollHandler);
    
//     return () => {
//       window.removeEventListener('scroll', bodyScrollHandler);
//     }
//   });

//   const bodyScrollHandler = (e) => {
//     if(window.scrollY > 150) {
//       setShrinkHeader(true);
//     }else if(window.scrollY < 100){
//       setShrinkHeader(false);
//     }
//   }

//   // Set language
//   const changeLanguage = (e, lang) => {
//     e.preventDefault()
//     setSelectedLang(lang)
//     setShowLangDropdown(false)
//     if(isCookieAccepted()) {
//       setLanguage(lang)
//     }
//   }

//   // On open mobile menu
//   const handleOpenMobileMenu = (e) => {
//     e.preventDefault()
//     setShowMobileMenu(true)
//   }

//   // On open mobile close
//   const handleCloseMobileMenu = (e) => {
//     setShowMobileMenu(false)
//   }

//   return (
//     <div className={shrinkHeader ? "landing-header sticky" : "landing-header"}>
//       <div className="landing-container">
//         <div className="landing-header__top">
//           <a href={`https://app.getcarbon.ai/signin?lang=${selectedLang}`} className="link">{t('auth.login')}</a>
//           <div className="divider"></div>
//           {/* Language toggle */}
//           <ClickAwayListener onClickAway={() => setShowLangDropdown(false)}>
//             <div className="lang-wrapper">
//               <div className="lang-wrapper__selected" onClick={() => setShowLangDropdown(!showLangDropdown)}>{selectedLang} <ExpandMore /></div>
//               {showLangDropdown && (
//                 <div className="lang-wrapper__dropdown">
//                   <a href="#/" className={selectedLang === 'fr' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'fr')}>Fr</a>
//                   <a href="#/" className={selectedLang === 'nl' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'nl')}>Nl</a>
//                   <a href="#/" className={selectedLang === 'en' ? 'active' : ''} onClick={(e) => changeLanguage(e, 'en')}>En</a>
//                 </div>
//               )}
//             </div>
//           </ClickAwayListener>
//         </div>
//         <div className="landing-header__main">
//           <Link to="/">
//             <div className="landing-logo">
//               <img src={logo} alt="Carbon"/>
//               <p>Carbon</p>
//             </div>
//           </Link>
//           {/* <AnimatedMenu t={t} /> */}
//           <div className="landing-header__right">
//             <a href="/#" className="menu-trigger" onClick={handleOpenMobileMenu}><Menu /></a>
//             {/* <a href={`https://app.getcarbon.ai/signin?lang=${selectedLang}`} className="link"><Lock />{t('auth.login')}</a> */}
//             <a href="mailto:hello@lawstud.io" className="button button--outline-primary"><span className="text">{t('landing.contact_us')}</span></a>
//             <Button 
//               icon={<CalendarToday />} 
//               text={t('landing.demo')} 
//               primary
//               className="landing-primary" 
//               onButtonClick={() => window.location.href = 'https://calendly.com/getcarbon'} 
//             />
//           </div>
//         </div>
//       </div>

//       {/* <MobileMenu onClose={handleCloseMobileMenu} show={showMobileMenu} /> */}
//     </div>
//   );
// }

// export default Header;