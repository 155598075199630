import React from 'react'

import { Source } from '../../../../assets/icons'
import AnimationBox from '../../../UI/AnimationBox'
import introImages from '../../../../assets/images/intro_images.png'

const Intro = ({ t, onDocumentsPreviewOpen }) => {

  // On open side panel
  const handleOpenSidePanel = () => {   
    onDocumentsPreviewOpen()
  }

  return (
    <div className="landing-intro landing-intro--dpo">
      <div className="landing-container">
        <div className="landing-intro__left">
          <AnimationBox>
            <h1 dangerouslySetInnerHTML={{ __html: t('landing.intro_dpo_title') }}></h1>
          </AnimationBox>
          <AnimationBox>
            <p className="bold">{t('landing.intro_dpo_text_1')}</p>
            <p className="regular" dangerouslySetInnerHTML={{ __html: t('landing.intro_dpo_text_2') }}></p>
          </AnimationBox>
          <div className="landing-intro__actions">
            <AnimationBox>
              <button className="btn btn--lg btn--green" onClick={handleOpenSidePanel}><Source /> {t('landing.intro_dpo_btn_text')}</button>
            </AnimationBox>
          </div>
        </div>
      </div>
      <AnimationBox
        className="intro-images"
        boxVariants={{
          visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
          hidden: { opacity: 0, x: 50 },
        }}
      >
        <img src={introImages} alt="" />
      </AnimationBox>
    </div>  
  );
}

export default Intro;