import React, { Fragment } from 'react';

const Paper = ({ signature = true, version2 }) => {
  return (
    <div className="paper">
      {version2 ? <Fragment>
        <div className="paper__line paper__line--sm"></div>
        <div className="paper__line paper__line--sm"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__row">
          <div className="paper__line"></div> 
          <div className="paper__line paper__line--red"></div> 
        </div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--md-2 paper__line--blue"></div>
        <div className="paper__line paper__line--md"></div>
        <div className="paper__line paper__line--md-3 paper__line--green"></div>
      </Fragment> : <Fragment>
        <div className="paper__line paper__line--sm"></div>
        <div className="paper__line paper__line--sm"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--lg"></div>
        <div className="paper__line paper__line--md"></div>
        <div className="paper__line paper__line--md"></div>
      </Fragment>}
      {signature && <div className="paper__signature">
        <i className="custom-icon-signature"></i>
      </div>}
    </div>
  );
}

export default Paper;