import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../context';

const NotFound = () => {
  const { t } = useContext(GlobalContext)
  
  return(
    <div className="not-found">
      <h1>{t('general.page_not_found')}</h1>
      {/* <p><Link to="/" className="button button--primary"><span className="text">{t('general.go_back')}</span></Link></p> */}
    </div>
  );
}

export default NotFound;