import React, { createContext, useState } from 'react'
import { currentLanguage } from '../i18n'
import fr from '../i18n/fr-copy.json'
import nl from '../i18n/nl-copy.json'
import en from '../i18n/en-copy.json'
import es from '../i18n/es-copy.json'
import it from '../i18n/it-copy.json'

export const GlobalContext = createContext()

const source = {
  fr,
  nl,
  en,
  es,
  it
}

const GlobalProvider = ({ children }) => {
  const [selectedLang, setSelectedLang] = useState(currentLanguage())
  const [menuWrapperLeftPosition, setMenuWrapperLeftPosition] = useState(0)
  const [numberOfDocsGenerated, setNumberOfDocsGenerated] = useState(0)
  const [numberOfDocsListener, setNumberOfDocsListener] = useState(null)
  const [numberOfDocsGeneratedFetched, setNumberOfDocsGeneratedFetched] = useState(false)
  const [libraries, setLibraries] = useState({})
  const [librariesFetched, setLibrariesFetched] = useState({
    fr: false,
    nl: false,
    en: false
  })

  // Translate
  const t = (key, params) => {
    const language = selectedLang
    const components = key.split('.')
    if(!source[language]) {
      return `Missing translation for ${key}`
    }
    const section = source[language][components[0]]
    if(!section) {
      return `Missing translation for ${key}`
    }
    let value = section[components[1]]
    if(!value) {
      return `Missing translation for ${key}`
    }
    if(!!params) {
      for(let p in params) {
        value = value.replace(`{{${p}}}`, params[p])
      }
    }
    return value
  }

  return (
    <GlobalContext.Provider value={{
      selectedLang,
      setSelectedLang,
      t,
      menuWrapperLeftPosition,
      setMenuWrapperLeftPosition,
      numberOfDocsGenerated,
      setNumberOfDocsGenerated,
      numberOfDocsListener,
      setNumberOfDocsListener,
      numberOfDocsGeneratedFetched,
      setNumberOfDocsGeneratedFetched,
      librariesFetched,
      setLibrariesFetched,
      libraries,
      setLibraries,
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider