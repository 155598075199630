import React from 'react';

import AnimationBox from '../../UI/AnimationBox'
import partner1 from '../../../assets/images/partner_1.png';
import partner2 from '../../../assets/images/partner_2.png';
import partner3 from '../../../assets/images/partner_3.png';
import partner4 from '../../../assets/images/partner_4.png';
import partner5 from '../../../assets/images/partner_5.png';
import partner6 from '../../../assets/images/partner_6.png';
import partner7 from '../../../assets/images/partner_7.png';
import partner8 from '../../../assets/images/partner_8.png';

const Partners = ({ t }) => {
  return (
    <div className="landing-partners">
      <div className="landing-container">
        <AnimationBox>
          <h5>{t('landing.partners_title')}</h5>
        </AnimationBox>
        <div className="landing-partners__inner">
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner1} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.15 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner2} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.3 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner3} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.45 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner4} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.6 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner5} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.75 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner8} alt=""/>  
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.9 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner6} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1.05 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={partner7} alt=""/>
          </AnimationBox>
        </div>
      </div>
    </div>
  );
}

export default Partners;