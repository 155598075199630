import firebase from './firebase'

// Fetch articles
const fetch_blog_articles = async () => {
  try {
    const res = await firebase.firestore().collection('help_articles').where('projects', 'array-contains', 'carbon-landing-blog').get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch articles error', err)
  }
}

// Fetch categories
const fetch_blog_categories = async () => {
  try {
    const res = await firebase.firestore().collection('help_categories').where('projects', 'array-contains', 'carbon-landing-blog').get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch categories error', err)
  }
}



export {
  fetch_blog_articles,
  fetch_blog_categories,
}