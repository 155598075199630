import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'react-cookies';
import { CARBON_COOKIE, CARBON_PAGINATION_LS, CARBON_SORT_LS, CARBON_SELECTED_TEAM_LS, CARBON_TEAM_PAGINATION_LS, CARBON_TEAM_COLUMNS_LS, CARBON_TEAM_COLUMNS_ORDER_LS, CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS, CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS, CARBON_TEAM_SORT_LS } from '../constants';
import config from '../config.json';

const sortedArrayFromObject = (obj, sorter) => {
  let arr = []
  for(let key in obj) {
    let item = obj[key]
    item.id = key
    arr.push(item)
  }
  if(sorter) {
    arr.sort(sorter)
  }
  return arr
}

const sorterWithPathAndOrder = (path, order) => (a,b) => {
  const splitted = path.split('.');
  let valA = a[splitted[0]] 
  let valB = b[splitted[0]]
  if(valA === undefined) valA = ''
  if(valB === undefined) valB = ''
  if(!valA && path.includes('__arr')) {
    valA = 0
  }
  if(!valB && path.includes('__arr')) {
    valB = 0
  }

  if(splitted.length === 1) {
    if(typeof valA === 'string' && typeof valB === 'string') {
      if(valA.toLowerCase() < valB.toLowerCase()) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA.toLowerCase() > valB.toLowerCase()) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }else {
      if(valA < valB) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA > valB) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }
  }else {
    if(splitted[1].includes('__arr')) {
      const newPath = splitted[1].split('__arr');
      if(valA[0][newPath[0]] < valB[0][newPath[0]]) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA[0][newPath[0]] > valB[0][newPath[0]]) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }else {
      if(valA[splitted[1]] < valB[splitted[1]]) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA[splitted[1]] > valB[splitted[1]]) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }
  }
}

const sortArrayOfObjects = (arr, value, order) => {
  if(!value) {
    return arr
  }
  const splitted = value.split('.');
  return arr.sort((a, b) => {
    let valA = splitted.length === 1 ? a[splitted[0]] : a[splitted[0]] ? a[splitted[0]][splitted[1]] : '';
    let valB = splitted.length === 1 ? b[splitted[0]] : b[splitted[0]] ? b[splitted[0]][splitted[1]] : '';
    
    if(valA === undefined) valA = ''
    if(valB === undefined) valB = ''

    if(typeof valA === 'string') {
      if(valA.toLowerCase() < valB.toLowerCase()) return -1 * (order === 'desc' ? 1 : -1)
      else if(valA.toLowerCase() > valB.toLowerCase()) return 1 * (order === 'desc' ? 1 : -1)
      else return 0
    }else {
      if(valA < valB) return -1 * (order === 'desc' ? 1 : -1)
      else if(valA > valB) return 1 * (order === 'desc' ? 1 : -1)
      else return 0
    }
  });
}

const getCreatedAtFromDocuments = (documents) => {
  const arr = [];
  for(let key in documents) {
    const value = documents[key].meta ? documents[key].meta.created : null;
    if(value) {
      const label = moment(value).format('MMMM YYYY');
      const start = moment(value).startOf('month').valueOf();
      const end = moment(value).endOf('month').valueOf();
      arr.push({ value, label, start, end });
    }
  }
  // return only unique objects
  return arr.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i);
}

// const dateValueFormat = 'YYYY-MM-DD[T]HH:mmZZ'
const dateValueFormat = 'DD/MM/YYYY'
const dateTimeValueFormat = 'MMM DD, YYYY HH:mm'

const urlSuffixForEnvironment = (environment, staging = false) => {
  if(environment === 'production' && staging) {
    return '_stg'
  }
  switch(environment) {
    case 'development':
      return '_dev'
    default:
      return ''
  }
}

const getAllParentFolders = (allFolders, folder, folders = []) => {
  if(!folder) {
    return folders;
  }
  const parentFolderId = folder.parentFolder;
  if(parentFolderId) {
    const pF = [...allFolders].find(f => f.id === parentFolderId);
    return pF ? getAllParentFolders(allFolders, pF, [...folders, pF]) : [];
  }else {
    return folders;
  }
}

const folderHasSubfolders = (folders, folder) => {
  return !!folders.find(f => f.parentFolder === folder.id);
}

const folderHasTemplates = (templates, folder) => {
  const arr = [];
  for(let key in templates) {
    arr.push({ ...templates[key], id: key });
  }
  return !!arr.find(t => !t.deleted && t.folderId && t.folderId.includes(folder.id));
}

const getFirstLevelSubfolders = (folders, folder) => {
  return folder ? [...folders].filter(f => f.parentFolder === folder.id) : [...folders].filter(f => f.parentFolder === null || f.parentFolder === undefined);
}

const isOverflown = (el) => {
  if(el) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = el;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }else {
    return false;
  }
}

const convertToTemplateObjWithUniqueVarIndexes = (obj) => {
  const copyOfTemplate = {...obj};
  const tmplSections = [...copyOfTemplate.sections];
  const updatedSections = tmplSections.map((s, i) => {
    const section = {...s};
    if(section.variable) {
      section.idx = `${section.variable}-${uuidv4()}`;
      return section;
    }else if(section.variables) {
      section.variables = [...section.variables].map((v => ({...v, idx: `${v.variable}-${uuidv4()}`})));
      return section;
    }else {
      return section;
    }
  });
  copyOfTemplate.sections = updatedSections;
  return copyOfTemplate;
}

// Get last X days array
const getLastXDaysArr = (numOfDays) => {
  return [...new Array(numOfDays)].map((d, idx) => moment().startOf("day").subtract(idx, "days")).reverse();
}

// Get days in month array
const getDaysInMonthArr = () => {
  let daysInMonth = moment().daysInMonth();
  const arrDays = [];

  while(daysInMonth) {
    const current = moment().date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;
  }

  return arrDays.reverse();
}

// Get all months in year
const getAllMonths = () => {
  const months = moment.months();
  return [...months].map(function(m,i){ return moment().month(i) });
}

// Get dates between two dates
const getDatesBetween = function(startDate, endDate) {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).endOf('day');
  dates.unshift(currDate.clone());
  
  while(currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(moment(currDate.clone().toDate()));
  }
  
  return dates;
};

// Save cookie
const acceptCookies = (obj) => {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 730) // expire in 2 years
  cookie.save(
    CARBON_COOKIE, 
    JSON.stringify(obj), 
    { 
      path: '/', 
      expires: expires
    }
  );
}

// Check if cookie exist
const isCookieAccepted = () => {
  return cookie.load(CARBON_COOKIE);
}

// Read file
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader(); 
    reader.onload = () => {
      resolve(reader.result);
    }; 
    reader.onerror = reject; 
    reader.readAsArrayBuffer(file);
  })
}

// Convert base64 to blob
const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

// Blob to base64
const blobToBase64 = async (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

// Check if data changed TODO - check only for field that is active(changing), add new param fieldKey 
const didObjectChanged = (newObj, oldObj, exclude = [], innerExclude = []) => {
  if(Object.keys(newObj).length !== Object.keys(oldObj).length) {
    for(let key in newObj) {
      if(!exclude.includes(key)) {
        const innerVal = newObj[key];
        const oldInnerVal = oldObj[key];
        if(innerVal === Object(innerVal)) { // check if object
          if(!Array.isArray(innerVal)) { // if not array 
            for(let innerKey in innerVal) {
              if(!innerExclude.includes(`${innerVal}.${innerKey}`)) {
                if(oldInnerVal) {
                  if(innerVal[innerKey] !== oldInnerVal[innerKey]) {
                    // console.log('not equal, inner object values not equal');
                    return true;
                  }
                }else {
                  if(innerVal[innerKey] !== '') {
                    // console.log('not equal, inner object value not empty');
                    return true;
                  }
                }
              }
            }
          }else { // array
            // if(!exclude.includes(key)) {
              if(oldInnerVal && oldInnerVal.length > innerVal.length) {
                return true;
              }else {
                for(let i = 0; i < innerVal.length; i++) {
                  if(!oldInnerVal || !oldInnerVal.includes(innerVal[i]) || oldInnerVal.length !== innerVal.length) {
                    // console.log('equal, array is different')
                    return true;
                  }
                }
              }
            // }
          }
        }else {
          if(oldInnerVal && innerVal !== oldInnerVal) {
            return true;
          }else if(!oldInnerVal && innerVal !== '') {
            return true;
          }
        }
      }
    }
  }else {
    for(let key in newObj) {
      if(!exclude.includes(key)) {
        const innerVal = newObj[key];
        const oldInnerVal = oldObj[key];
        if(innerVal === Object(innerVal)) { // check if object
          if(!Array.isArray(innerVal)) { // if not array 
            for(let innerKey in innerVal) {
              if(!innerExclude.includes(`${key}.${innerKey}`)) {
                if(innerVal[innerKey] !== oldInnerVal[innerKey]) {
                  // console.log('equal, inner object values not equal');
                  return true;
                }
              }
            }
          }else {
            // if(!exclude.includes(key)) {
              if(oldInnerVal && oldInnerVal.length > innerVal.length) {
                return true;
              }else {
                for(let i = 0; i < innerVal.length; i++) {
                  if(!oldInnerVal || !oldInnerVal.includes(innerVal[i]) || oldInnerVal.length !== innerVal.length) {
                    // console.log('equal, array is different')
                    return true;
                  }
                }
              }
            // }
          }
        }else {
          if(oldInnerVal && innerVal !== oldInnerVal) {
            return true;
          }else if(!oldInnerVal && innerVal !== '') {
            return true;
          }
        }
      }
    }
  }

  return false;
}

// Blob to file
const blobToFile = (blob, name) => {
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return blob;
}

// Get file data
const getFileData = (file, callback) => {
  if (file) {
    var reader = new FileReader();

    reader.onload = (e) => {
      let components = file.name.split('.')
      const format = components[components.length - 1]
      components.splice(components.length - 1, 1)
      const name = components.join('.')
      const type = file.type
      callback({ data: e.target.result, name: name, format: format, type: type })
    }
    reader.onerror = (err) => {
      console.log('reader on error', err)
    }
    reader.readAsDataURL(file);
  }
}

// Filter documents helper
const filterDocumentsHelper = (data, filters, templateIncludes) => {
  const filtersToCheck = {};

  for(let key in filters) {
    if(filters[key] !== '' && key !== 'folder') {
      filtersToCheck[key] = filters[key];
    }
  }
  const filterKeys = Object.keys(filtersToCheck);
  // console.log(filterKeys, filtersToCheck)
  let filtered = {};

  if(filters.folder) {
    for(let key in data) {
      if(data[key].folderId && data[key].folderId.includes(filters.folder)) {
        if(filterKeys.length > 0) {
          for(let i = 0; i < filterKeys.length; i++) {
            const fKey = filterKeys[i];
            if(fKey === 'date_before') {
              if(data[key].meta.created < filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'date_after') {
              if(data[key].meta.created > filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'name') {
              let searchPhrase = filters[fKey];
              const searchedDocument = data[key];
              const docName = searchedDocument.name.toLowerCase();
              if(docName.includes(searchPhrase)) {
                filtered[key] = data[key];
              }else {
                let added = false
                for(let valueKey in searchedDocument.values) {
                  if(searchedDocument.values[valueKey].toLowerCase().includes(searchPhrase)) {
                    filtered[key] = data[key];
                    added = true
                    break
                  }
                }
                if(!added && templateIncludes(searchedDocument.template, searchPhrase)) {    
                  filtered[key] = data[key]   
                  added = true
                }
                if(!added && filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else {
              if(data[key][fKey] === filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }
          }
        }else {
          filtered[key] = data[key];
        }
      }
    }
  }else {
    for(let key in data) {
      if((data[key].folderId && data[key].folderId.length === 0) || data[key].folderId === undefined) {
        if(filterKeys.length > 0) {
          for(let i = 0; i < filterKeys.length; i++) {
            const fKey = filterKeys[i];
            if(fKey === 'date_before') {
              if(data[key].meta.created < filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'date_after') {
              if(data[key].meta.created > filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'name') {
              let searchPhrase = filters[fKey];
              const searchedDocument = data[key];
              const docName = searchedDocument.name.toLowerCase();
              if(docName.includes(searchPhrase)) {
                filtered[key] = data[key];
              }else {
                let added = false
                for(let valueKey in searchedDocument.values) {
                  if(typeof searchedDocument.values[valueKey] === 'string' && searchedDocument.values[valueKey].toLowerCase().includes(searchPhrase)) {
                    filtered[key] = data[key];
                    added = true
                    break
                  }
                }
                if(!added && templateIncludes(searchedDocument.template, searchPhrase)) {    
                  filtered[key] = data[key]   
                  added = true
                }
                if(!added && filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else {
              if(data[key][fKey] === filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }
          }
        }else {
          filtered[key] = data[key];
        }
      }
    }
  }
  
  return filtered;
}

// Filter folders helper
const filterFoldersHelper = (data, filters) => {
  const filtersToCheck = {};

  for(let key in filters) {
    if(!!filters[key]) {
      filtersToCheck[key] = filters[key];
    }
  }
  if(!filters.parentFolder) {
    filtersToCheck.parentFolder = null;
  }
  const filterKeys = Object.keys(filtersToCheck);
  // console.log(filterKeys, filtersToCheck)
  let filtered = {};
  data.forEach(item => {
    if(filterKeys.length > 0) {
      for(let i = 0; i < filterKeys.length; i++) {
        const fKey = filterKeys[i];
        if(fKey === 'date_before') {
          if(item.meta.created < filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else if(fKey === 'date_after') {
          if(item.meta.created > filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else if(fKey === 'name') {
          if(item[fKey].includes(filters[fKey])) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else {
          if(item[fKey] === filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }
      }
    }else {
      filtered[item.id] = item;
    }
  });
  let arr = [];
  for(let key in filtered) {
    arr.push(filtered[key]);
  }
  
  return arr;
}

const availableOn = (arr) => {
  return arr && arr.includes(config.environment);
}

const areSectionConditionsMet = (section, values) => {
  if((!section.conditions || section.conditions?.length === 0) && !section.condition) {
    return true
  } else if(section.conditions?.length > 0) {
    for(let i in section.conditions) {
      let cond = section.conditions[i]
      if(!isConditionMet(cond, values)) {
        return false
      }
    }
    return true
  } else {
    return isConditionMet(section.condition, values)
  }
}

const isConditionMet = (cond, values) => {
  let targetValue = cond.value
  let sourceValue = values[cond.variable]
  if(typeof targetValue === 'number') {
    sourceValue = parseFloat(sourceValue)
  } 
  if(cond.relation === 'EQ') {
    return sourceValue === targetValue
  } else if(cond.relation === 'NE') {
    return sourceValue !== targetValue
  } else if(cond.relation === 'GT') {
    return sourceValue > targetValue
  } else if(cond.relation === 'LT') {
    return sourceValue < targetValue
  } else if(cond.relation === 'IN') {
    if(!sourceValue) {
      sourceValue = []
    }
    if(targetValue instanceof Array) {
      let checkedCount = 0
      for(let tv of targetValue) {
        if(sourceValue.includes(tv)) {
          checkedCount++
        }
      }
      return checkedCount === targetValue.length
    } else {
      return sourceValue.includes(targetValue)
    }
  }
}

const isObjectEmpty = (obj, exclude = []) => {
  let empty = true;
  let breakLoop = false;
  for(let key in obj) {
    if(!exclude.includes(key) && !breakLoop) {
      if(obj[key] === Object(obj[key])) {
        for(let innerKey in obj[key]) {
          if(obj[key][innerKey] && obj[key][innerKey] !== '') {
            empty = false;
            breakLoop = true;
          }
        }
      }else { 
        if(obj[key] && obj[key] !== '') {
          empty = false;
          breakLoop = true;
        }
      }
    }
  }
  return empty;
}

const numberOfValuesInObj = (obj, exclude = []) => {
  let number = 0;
  for(let key in obj) {
    if(!exclude.includes(key)) {
      if(obj[key] === Object(obj[key])) {
        let breakLoop = false;
        if(Array.isArray(obj[key])) {
          let arrLength = obj[key].length;
          number += arrLength;
        }else {
          for(let innerKey in obj[key]) {
            if(obj[key][innerKey] && obj[key][innerKey] !== '' && !breakLoop) {
              number++;
              breakLoop = true;
            }
          }
        }
      }
    }
  }
  return number;
}

const EVENT_TYPES = {
  DOCUMENT_CREATE: 'document_create',
  DOCUMENT_DELETE: 'document_delete',
  DOCUMENT_DOWNLOAD: 'document_download',
  DOCUMENT_DOWNLOAD_PDF: 'document_download_pdf',
  DOCUMENT_DOWNLOAD_DOCX: 'document_download_docx',
  DOCUMENT_PREVIEW: 'document_preview'
}

const checkIfTeamChanged = (newObj, oldObj) => {
  let changed = false 
  let specificKeys = ['cards_order', 'document_lists', 'users', 'users_emails']

  if(Object.keys(newObj).length !== Object.keys(oldObj).length) {
    return true
  }

  for(let key in newObj) {
    let val = newObj[key]
    let oldVal = oldObj[key]
    if(!oldObj.hasOwnProperty(key)) {
      changed = true
    }
    if(!changed) {
      if(!specificKeys.includes(key)) {
        if(val !== oldVal) {
          changed = true
        }
      }else {
        if(key === 'cards_order') {
          if(Object.keys(newObj[key]).length !== Object.keys(oldObj[key]).length) {
            changed = true 
          }else {
            for(let col in newObj[key]) {
              if(!oldObj[key][col]) {
                changed = true
              }else {
                if(newObj[key][col].order.length !== oldObj[key][col].order.length) {
                  changed = true
                }else {
                  for(let i = 0; i < newObj[key][col].order.length; i++) {
                    if(newObj[key][col].order[i] !== oldObj[key][col].order[i]) {
                      changed = true
                      break
                    }
                  }
                }
              }
            }
          }
        }else if(key === 'document_lists') {
          if(newObj[key].length !== oldObj[key].length) {
            changed = true 
          }else {
            for(let i = 0; i < newObj[key].length; i++) {
              if(newObj[key][i] !== oldObj[key][i]) {
                changed = true
                break
              }
            }
          }
        }else if(key === 'users_emails') {
          if(newObj[key].length !== oldObj[key].length) {
            changed = true 
          }else {
            for(let i = 0; i < newObj[key].length; i++) {
              if(!oldObj[key].includes(newObj[key][i])) {
                changed = true
                break
              }
            }
          }
        }
      }
    }
  }

  return changed
}

// Save pagination data to LS
const savePaginationDataToLS = (numOfItemsToShow, currentPage, all, type) => {
  const paginationLS = localStorage.getItem(CARBON_PAGINATION_LS)
  if(paginationLS) {
    const paginationObj = JSON.parse(paginationLS)
    let obj = {...paginationObj}
    obj[type] = { items: numOfItemsToShow, current: currentPage, all }
    localStorage.setItem(CARBON_PAGINATION_LS, JSON.stringify(obj))
  }else {
    let obj = {}
    obj[type] = { items: numOfItemsToShow, current: currentPage, all }
    localStorage.setItem(CARBON_PAGINATION_LS, JSON.stringify(obj))
  }
}

// Save team pagination data to LS
const saveTeamPaginationDataToLS = (teamId, numOfItemsToShow, currentPage, all, type) => {
  const lsKey = CARBON_TEAM_PAGINATION_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = { items: numOfItemsToShow, current: currentPage, all }
    }else {
      obj[teamId] = {
        [type]: { items: numOfItemsToShow, current: currentPage, all }
      }
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: {
        [type]: { items: numOfItemsToShow, current: currentPage, all }
      }
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team columns data to LS - column names which whould be visible in the list view
const saveTeamColumnsDataToLS = (teamId, data, type) => {
  const lsKey = CARBON_TEAM_COLUMNS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = data
    }else {
      obj[teamId] = {
        [type]: data
      }
    }
    if(data.length === 0) {
      if(obj[teamId] && obj[teamId][type]) delete obj[teamId][type] 
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }else {
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }else {
    if(data.length > 0) {
      let obj = {
        [teamId]: {
          [type]: data
        }
      }
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }
}

// Save team columns order data to LS 
const saveTeamColumnsOrderDataToLS = (teamId, data, type) => {
  const lsKey = CARBON_TEAM_COLUMNS_ORDER_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = data
    }else {
      obj[teamId] = {
        [type]: data
      }
    }
    if(data.length === 0) {
      if(obj[teamId] && obj[teamId][type]) delete obj[teamId][type] 
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }else {
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }else {
    if(data.length > 0) {
      let obj = {
        [teamId]: {
          [type]: data
        }
      }
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }
}

// Save team documents column widths to LS
const saveTeamDocsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team contacts column widths to LS
const saveTeamContactsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Get carbon pagination data from LS
const getPaginationData = () => {
  const paginationLS = localStorage.getItem(CARBON_PAGINATION_LS)
  return JSON.parse(paginationLS)
}

// Get carbon pagination data from LS
const getTeamPaginationData = () => {
  const paginationLS = localStorage.getItem(CARBON_TEAM_PAGINATION_LS)
  return JSON.parse(paginationLS)
}

// Get carbon columns data from LS
const getTeamColumnsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_COLUMNS_LS)
  return JSON.parse(dataLS)
}

// Get carbon columns order data from LS
const getTeamColumnsOrderData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_COLUMNS_ORDER_LS)
  return JSON.parse(dataLS)
}

// Get carbon contacts columns widths data from LS
const getTeamContactsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon documents columns widths data from LS
const getTeamDocsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Remove carbon columns data from LS
const removeTeamColumnsData = () => {
  localStorage.removeItem(CARBON_TEAM_COLUMNS_LS)
}

// Remove carbon columns order data from LS
const removeTeamColumnsOrderData = () => {
  localStorage.removeItem(CARBON_TEAM_COLUMNS_ORDER_LS)
}

// Remove carbon contacts columns widts data from LS
const removeTeamContactsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamContactsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon docs columns widts data from LS
const removeTeamDocsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamDocsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Save sort to LS
const saveSortingToLS = (value, order, type) => {
  const sortLS = localStorage.getItem(CARBON_SORT_LS)
  if(sortLS) {
    const sortingObj = JSON.parse(sortLS)
    let obj = {...sortingObj}
    obj[type] = { value, order }
    localStorage.setItem(CARBON_SORT_LS, JSON.stringify(obj))
  }else {
    let obj = {}
    obj[type] = { value, order }
    localStorage.setItem(CARBON_SORT_LS, JSON.stringify(obj))
  }
}

// Save sort to LS
const saveTeamSortingToLS = (teamId, value, order, type) => {
  if(!teamId) return 

  const sortLS = localStorage.getItem(CARBON_TEAM_SORT_LS)
  if(sortLS) {
    const sortingObj = JSON.parse(sortLS)
    let obj = {...sortingObj}
    if(obj[teamId]) {
      obj[teamId] = {
        ...obj[teamId],
        [type]: { value, order }
      }
    }else {
      obj[teamId] = {
        [type]: { value, order }
      }
    }
    localStorage.setItem(CARBON_TEAM_SORT_LS, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: {
        [type]: { value, order }
      }
    }
    localStorage.setItem(CARBON_TEAM_SORT_LS, JSON.stringify(obj))
  }
}

// Get team sorting data
const getTeamSortingData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_SORT_LS)
  return JSON.parse(dataLS)
}

// Save selected team to LS
const saveSelectedTeamToLS = (value) => {
  if(isCookieAccepted()) {
    localStorage.setItem(CARBON_SELECTED_TEAM_LS, value)
  }
}

// Get selected team from LS
const getSelectedTeamFromLS = () => {
  const dataLS = localStorage.getItem(CARBON_SELECTED_TEAM_LS)
  return dataLS
}

const hasSufficientMembership = (membership, requirement) => {
  const memberships = ['free', 'beta_partner', 'premium', 'partner']
  return memberships.indexOf(membership) >= memberships.indexOf(requirement)
}

// Get name from member id
const getNameFromMemberObj = (member) => {
  let name = ''
  if(member.first_name) name = member.first_name
  if(name && member.last_name) name = `${name} ${member.last_name}`
  if(!name && member.email) name = member.email
  return name
}

const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const capitalizeWord = (s) => {
  return `${s.substring(0, 1).toUpperCase()}${s.substring(1, s.length)}`
}


export {
  sortedArrayFromObject,
  sorterWithPathAndOrder,
  getCreatedAtFromDocuments,
  dateValueFormat,
  dateTimeValueFormat,
  urlSuffixForEnvironment,
  getAllParentFolders,
  folderHasSubfolders,
  folderHasTemplates,
  getFirstLevelSubfolders,
  sortArrayOfObjects,
  isOverflown,
  convertToTemplateObjWithUniqueVarIndexes,
  getLastXDaysArr,
  getDaysInMonthArr,
  getAllMonths,
  getDatesBetween,
  acceptCookies,
  isCookieAccepted,
  base64toBlob,
  blobToBase64,
  readFileAsync,
  didObjectChanged,
  blobToFile,
  getFileData,
  filterDocumentsHelper,
  filterFoldersHelper,
  EVENT_TYPES,
  availableOn,
  areSectionConditionsMet,
  isObjectEmpty,
  numberOfValuesInObj,
  checkIfTeamChanged,
  savePaginationDataToLS,
  saveTeamPaginationDataToLS,
  saveTeamColumnsDataToLS,
  saveTeamColumnsOrderDataToLS,
  saveTeamContactsColumnWidthsToLS,
  saveTeamDocsColumnWidthsToLS,
  getPaginationData,
  getTeamPaginationData,
  getTeamColumnsData,
  getTeamColumnsOrderData,
  getTeamContactsColumnWidthsData,
  getTeamDocsColumnWidthsData,
  removeTeamColumnsData,
  removeTeamColumnsOrderData,
  removeTeamContactsColumnWidthsData,
  removeTeamDocsColumnWidthsData,
  saveSortingToLS,
  saveTeamSortingToLS,
  getTeamSortingData,
  saveSelectedTeamToLS,
  getSelectedTeamFromLS,
  hasSufficientMembership,
  getNameFromMemberObj,
  getBase64FromFile,
  capitalizeWord
}
