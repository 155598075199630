import React, { createContext, useReducer, useState } from 'react'

import { fetch_articles, fetch_categories } from '../services/help'
import { sortArrayOfObjects } from '../utils'

const GET_HELP_ARTICLES = 'GET_HELP_ARTICLES'
const GET_HELP_CATEGORIES = 'GET_HELP_CATEGORIES'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_HELP_ARTICLES:
      return {
        ...state,
        articles: action.payload.obj,
        articlesArr: action.payload.arr,
        whatsNewArticles: action.payload.whatsNewArr,
        articlesFetched: true
      }
    case GET_HELP_CATEGORIES:
      return {
        ...state,
        categories: action.payload.obj,
        categoriesArr: action.payload.arr,
        categoriesFetched: true
      }
    default:
      return state
  }
}

export const HelpContext = createContext()

const HelpProvider = ({ children }) => {
  const initialState = {
    articles: {},
    articlesArr: [],
    whatsNewArticles: [],
    articlesFetched: false,
    categories: {},
    categoriesArr: [],
    categoriesFetched: false,
  }

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch articles
  const fetchHelpArticles = async () => {
    try {
      const res = await fetch_articles()
      let arr = []
      let whatsNewArr = []
      for(let id in res) {
        if(res[id].category === 'whats_new') {
          whatsNewArr.push({ id, ...res[id] })
        }else {
          arr.push({ id, ...res[id] })
        }
      }
      dispatch({
        type: GET_HELP_ARTICLES,
        payload: {
          obj: res,
          arr: sortArrayOfObjects(arr, 'title.en', 'desc'),
          whatsNewArr: sortArrayOfObjects(whatsNewArr, 'title.en', 'desc')
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch help categories
  const fetchHelpCategories = async () => {
    try {
      const res = await fetch_categories()
      let arr = []
      for(let id in res) {
        if(id !== 'whats_new') {
          arr.push({ id, ...res[id] })
        }
      }
      dispatch({
        type: GET_HELP_CATEGORIES,
        payload: {
          obj: res,
          arr
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  return <HelpContext.Provider value={{
    articles: state.articles,
    articlesArr: state.articlesArr,
    whatsNewArticles: state.whatsNewArticles,
    articlesFetched: state.articlesFetched,
    categories: state.categories,
    categoriesArr: state.categoriesArr,
    categoriesFetched: state.categoriesFetched,
    fetchHelpArticles,
    fetchHelpCategories,
    selectedCategory,
    setSelectedCategory,
  }}>
    { children }
  </HelpContext.Provider>
}

export default HelpProvider