import React, { useContext } from 'react';

import AnimationBox from '../../UI/AnimationBox'
import contractPreview from '../../../assets/images/contract_preview.png';
import contractPreview2 from '../../../assets/images/contract_preview_2.png';
import contractPreview3 from '../../../assets/images/contract_preview_3.png';
import modelingDocuments from '../../../assets/images/modeling_documents_title.svg';
import modelingDocumentsEn from '../../../assets/images/modeling_documents_title_en.svg';
import modelingDocumentsNl from '../../../assets/images/modeling_documents_title_nl.svg';
import addinPreview from '../../../assets/images/addin_preview.png';
import WordDocument from '../../UI/WordDocument';
import { GlobalContext } from '../../../context'

const ModelingYourDocuments = ({ t }) => {
  const { selectedLang } = useContext(GlobalContext)

  let titleImgSrc = modelingDocuments

  if(selectedLang === 'en') {
    titleImgSrc = modelingDocumentsEn
  }else if(selectedLang === 'nl') {
    titleImgSrc = modelingDocumentsNl
  }

  return (
    <div className="landing-modeling-documents">
      <div className="landing-container">
        <div className="landing-modeling-documents__top">
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.25 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={contractPreview3} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={contractPreview2} alt=""/>
          </AnimationBox>
          <AnimationBox
            boxVariants={{
              visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.75 } },
              hidden: { opacity: 0, x: -50 },
            }}
          >
            <img src={contractPreview} alt=""/>
          </AnimationBox>
        </div>
        <div className="landing-modeling-documents__title">
          <AnimationBox>
            {/* <img src={titleImgSrc} alt=""/> */}
            <div className='modeling-label'>
              <span>{t('landing.modelling')}</span>
            </div>
          </AnimationBox>
        </div>
        <div className="landing-modeling-documents__inner">
          <div className="landing-modeling-documents__inner_left">
            <AnimationBox>
              <div className="landing-label landing-label--10 landing-label--sm"><span>{t('landing.modeling_documents_label_1')}</span></div>
            </AnimationBox>
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.modeling_documents_title_1')}</h2>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.modeling_documents_text_1')}</p>
            </AnimationBox>
            <AnimationBox
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
                hidden: { opacity: 0, x: 50 },
              }}
            >
              <WordDocument />
            </AnimationBox>
            <div className="border-right"></div>
          </div>
          <div className="landing-modeling-documents__inner_right">
            <AnimationBox>
              <div className="landing-label landing-label--11 landing-label--sm"><span>{t('landing.modeling_documents_label_2')}</span></div>
            </AnimationBox>
            <AnimationBox>
              <h2 className="landing-h2">{t('landing.modeling_documents_title_2')}</h2>
            </AnimationBox>
            <AnimationBox>
              <p className="landing-p">{t('landing.modeling_documents_text_2')}</p>
            </AnimationBox>
            <AnimationBox
              boxVariants={{
                visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <img src={addinPreview} alt=""/>
            </AnimationBox>
          </div>
        </div>
      </div>
      <div className="landing-modeling-documents__bgs">
        <div className="bg-1"></div>
        <div className="bg-2"></div>
      </div>
    </div>
  );
}

export default ModelingYourDocuments;