import React from 'react';

import AnimationBox from '../../UI/AnimationBox'
import paperPlaceWithDots from '../../../assets/images/paper-plane-with-dots.png';
import calendarImg from '../../../assets/images/calendar_2.png';

const Deadlines = ({ t }) => {
  return(
    <div className="landing-deadlines">
      <div className="landing-container landing-container--deadlines">
        <div className="landing-deadlines__inner">
          <AnimationBox>
            <div className="landing-label landing-label--3"><span>{t('landing.alerts_label')}</span></div>
          </AnimationBox>
          <AnimationBox>
            <h2 className="landing-h2">{t('landing.alerts_title')}</h2>
          </AnimationBox>
          <AnimationBox>
            <p>{t('landing.alerts_text')}</p>
          </AnimationBox>
          <AnimationBox className="landing-deadlines__inner_icons">
            <img src={paperPlaceWithDots} alt=""/>
          </AnimationBox>
          <div className="landing-deadlines__inner_dates">
            <AnimationBox>
              <div className="date">
                <div className="date__month">{t('landing.sept')}</div>
                <div className="date__day">07</div>
              </div>
            </AnimationBox>
            <AnimationBox>
              <div className="date">
                <div className="date__month">{t('landing.oct')}</div>
                <div className="date__day">12</div>
              </div>
            </AnimationBox>
            <AnimationBox>
              <div className="date">
                <div className="date__month">{t('landing.nov')}</div>
                <div className="date__day">27</div>
              </div>
            </AnimationBox>
          </div>
          <AnimationBox>
            <img src={calendarImg} alt="" className="landing-deadlines__inner_calendar" />
          </AnimationBox>
        </div>

        <div className="landing-deadlines__dots">
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
          <span></span><span></span><span></span><span></span><span></span>
        </div>
      </div>
      <AnimationBox className="landing-deadlines__calendar">
        <img src={calendarImg} alt="" />
      </AnimationBox>
    </div>
  );
}

export default Deadlines;