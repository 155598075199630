import { CalendarMonth } from '../../../assets/icons'
import AnimationBox from '../../UI/AnimationBox'
import Button from '../../UI/Button'

const TryCarbon2 = ({ t, version = 'dark', className = '' }) => {

  return (
    <div className={`landing-try landing-try--${version} ${className}`}>
      <div className="landing-container">
        <AnimationBox>
          <h2 className="landing-h2">{t('landing.try_title')}</h2>
        </AnimationBox>
        <AnimationBox>
          <p>{t('landing.try_text_1')}</p>
          <p>{t('landing.try_text_2')}</p>
        </AnimationBox>
        <div className="landing-try__actions">
          <AnimationBox>
            <a href="mailto:hello@lawstud.io" className="button landing--white">{t('landing.contact_us')}</a>
          </AnimationBox>
          <AnimationBox>
            <Button 
              icon={<CalendarMonth />} 
              text={t('landing.demo')}  
              onButtonClick={() => window.open('https://calendly.com/getcarbon', '_blank')}
              className="landing-primary landing-primary--lg"
              primary
            />
          </AnimationBox>
        </div>
      </div>
    </div>
  );
}

export default TryCarbon2